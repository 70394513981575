import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import Delete from "../../assets/delete.svg";
import Loader from "../../Loader";
import DeleteRequest from "../apiConnections/deleteRequest";
import PostRequest from "../apiConnections/postRequest";
function DeleteQuestionnaire({ setId, open, handleClose, handleSuccess, setDeleted }) {
  const { enqueueSnackbar } = useSnackbar();
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [showLoader, setShowLoader] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    textAlign: "center",
    borderRadius: "15px",
    p: 4,
  };

  const deleteQuestionnaireSetAPI = async () => {
    setShowLoader(true);
    const payload = { setId: setId };
    try {
        const refreshToken = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            {},
            {},
            "refreshToken"
        );
        if (refreshToken) {
            let endpoint = process.env.REACT_APP_DELETE_TP_QUESTIONNAIRE_SET;
            await DeleteRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
                payload
            )
            .then((resChart) => {
                if (resChart.status === 200) {
                    enqueueSnackbar("Questionnaire set deleted successfully", {
                        variant: "success",
                        autoHideDuration: 3000,
                    });
                    handleClose();
                    setTimeout(() => {
                        handleSuccess();
                    }, 3000);
                    setShowLoader(false);
                } else {
                    enqueueSnackbar(resChart?.data?.message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });
                    setShowLoader(false); // Update loader state
                }
            })
            .catch((errChart) => {
                enqueueSnackbar(errChart.message, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
                setShowLoader(false); // Update loader state
            });
        } else {
            enqueueSnackbar("Token error", { variant: "error" });
            setShowLoader(false); // Update loader state
        }
    } catch (error) {
        enqueueSnackbar(error.message || "Something went wrong", {
            variant: "error",
        });
        setShowLoader(false); // Update loader state
    }
};
  return (
    <>
      <Loader show={showLoader} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={
          theme == "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"
        }
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <img src={Delete} alt="icon" />
            <h4 style={{ wordBreak: "break-word" }}>
              Are you sure you want to delete this Questionnaire set ?
            </h4>
          </Typography>
          <Grid item xs={12} md={12} lg={12} className="center-vendor">
            <Button
              variant="contained"
              className="click-to-begin m-l model-btns cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="click-to-begin model-btns"
              onClick={() => deleteQuestionnaireSetAPI()}
            >
              Confirm
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default DeleteQuestionnaire;
