import * as React from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import {Container} from '@mui/material';
import Profile from "../sections/auth/profile/Profile";
import './css/Profile.css';

// ----------------------------------------------------------------------

export default function ProfilePage() {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title> Profile | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false}>
        <Profile/> 
      </Container>
    </>
  );
}
