import react, {useState ,useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MailSend from '../assets/mailsend.svg';

import {
    Grid
  } from "@mui/material";

export default function ModalDialog({ handleClose, open, contentData, onSubmit }) {
  
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const [content, setContent] = useState(contentData);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    textAlign: 'center',
    borderRadius: '15px',
    p: 4,
};
  useEffect(()=>{
    setInterval(()=>{
      const themeName = localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : 'Dark';
      setTheme(themeName);
    }, 2000)
    }, []) 

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={(theme=='Dark') ? 'dark-modal modal-popup' : 'light-modal modal-popup'}
          >
        <Box sx={style}>
        {content}
            <Grid item xs={12} md={12} lg={12} className="center-vendor">
             <Button variant="contained" className="click-to-begin m-l model-btns cancel-btn" onClick={handleClose}>
              Cancel
             </Button>
             <Button variant="contained" className="click-to-begin model-btns" onClick={()=> onSubmit()}>
              Confirm
             </Button>
            </Grid>
          </Box>
       </Modal>
    </div>
   );
}