import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Tabs,
    TextareaAutosize,
    TextField,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import GetRequest from "../../components/apiConnections/getRequest";
import PostRequest from "../../components/apiConnections/postRequest";
import Loader from "../../Loader";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <>
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        </>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

function QuestionnaireSetAnswer() {
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [showLoader, setShowLoader] = useState(false);
    const [setDetails, setSetDetails] = useState({});
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [value, setValue] = React.useState(0);
    const [showPublishForm, setShowPublishForm] = useState(false);
    const [formData, setFormData] = useState({
        type: "",
        fileType: "", //excel,pdf
        description: "",
        categoriesDetails: [],
        setDetails: {},
    });
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false); // Track whether any checkbox is selected

    useEffect(() => {
        if (location?.state?.data["_id"]) {
            setSetDetails(location?.state?.data);
            setFormData({ ...formData, setDetails: location?.state?.data });
            getQuestionnaireSetDetails();
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getQuestionnaireSetDetails = async () => {
        console.log()
        setShowLoader(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_QUESTIONNAIRE_SET_DETAIL_BY_TP}?setId=${location?.state?.data["_id"]}&setType=${location?.state?.data["type"]}`,
                    {}
                )
                    .then((resChart) => {
                        let data = resChart.data.data;
                        setCategoryDetails(data?.data);
                        setShowLoader(false);
                    })
                    .catch((errChart) => {
                        enqueueSnackbar(errChart.message, { variant: "error" });
                        setShowLoader(false);
                    });
            } else {
                setShowLoader(false);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            setShowLoader(false);
        }
    };

    const questionnaireAnswerConfig = (event, i) => {
        const ans = event.target.value;
        let arr = categoryDetails.slice(0);
        const answers = arr[value]?.question[i]?.answer || [];

        if (answers?.includes(ans)) {
            let filteredArr = answers.filter((item) => item !== ans);
            arr[value].question[i].answer = filteredArr;
            setCategoryDetails([...arr]);
            saveQuestionnaireAnswer([...arr]);
        } else {
            arr[value].question[i].answer = [...answers, ans];
            setCategoryDetails([...arr]);
            saveQuestionnaireAnswer([...arr]);
        }
    };



    const readRadioButton = (event, i) => {
        const ans = event.target.value;
        let arr = categoryDetails.slice(0);
        arr[value].question[i].answer = [ans];
        setCategoryDetails([...arr]);
        saveQuestionnaireAnswer([...arr]);
    };


    const checkMissingAnswer = (data) => {
        console.log(data)
        for (const obj of data) {
            for (const val of obj.question) {
                if (!val?.answer?.length) {
                    return { missing: true, category: obj.category };
                }
            }
        }
        return { missing: false, category: null };
    }

    const saveQuestionnaireAnswer = async (arr, type) => {
        let payload = {
            setId: location?.state?.data["_id"],
            categories: arr
        };

        const endpoint = process.env.REACT_APP_SAVE_TP_QUESTIONNAIRE_ANSWER;
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
                    payload
                )
                    .then((res) => {
                        console.log("saved successfully",res)
                        if (type === "save") {
                            enqueueSnackbar(res.data?.message, { variant: "success" });
                            navigate("../upload-tab", {
                                state: { tabIndex: location.state?.tabIndex },
                            })
                        }
                    })
                    .catch((errVendor) => {
                        enqueueSnackbar(errVendor.message, { variant: "error" });
                        setShowLoader(false);
                    });
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            setShowLoader(false);
        }
    }

    const uploadQuestionnaire = async (type) => {
        setShowLoader(true);

        let payload = {
            setId : location?.state?.data["_id"] || null,
            accessType: formData.type,
            description: formData.description,
            fileType: type,
            categoriesDetails: formData.categoriesDetails,
            setDetails: formData.setDetails,
        };

        const endpoint = process.env.REACT_APP_PUBLISH_TP_QUESTIONNAIRE_SET_AS_FILE;
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
                    payload
                )
                    .then((res) => {
                        enqueueSnackbar(res.data.message, { variant: "success" });
                        setShowLoader(false);
                        navigate("../upload-tab", {
                            state: { tabIndex: location.state?.tabIndex },
                        });
                    })
                    .catch((errVendor) => {
                        enqueueSnackbar(errVendor.message, { variant: "error" });
                        setShowLoader(false);
                    });
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            setShowLoader(false);
        }
    };

    const checkValidation = (type) => {
        uploadQuestionnaire(type);
    };

    return (
        <>
            <Loader show={showLoader} />
            <Helmet>
                <title> Questionnaire | Genesis Platform</title>
            </Helmet>
            <Typography variant="h4" sx={{ mb: 2, mt: 5 }}>
                <span className="heading-main">Questionnaire Upload</span>
            </Typography>

            {!showPublishForm ? (
                <>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <Button
                            variant="contained"
                            className="m-l-0 click-to-begin"
                            onClick={() =>
                                navigate("../upload-tab", {
                                    state: { tabIndex: location.state?.tabIndex },
                                })
                            }
                        >
                            Back
                        </Button>
                    </div>
                    <div
                        className="tabs-bg"
                        style={{ marginTop: "14px", marginBottom: "40px" }}
                    >
                        <Box sx={{ flexGrow: 1, display: "flex" }}>
                            {categoryDetails?.map((element, index, array) => {
                                <Tab key={index} label={"test"} {...a11yProps(`tab11`)} />;
                            })}
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: "divider" }}
                            >
                                {categoryDetails?.map((tab, index) => (
                                    <Tab
                                        key={tab.category.toString() + index}
                                        label={tab.category}
                                        {...a11yProps(index)}
                                    />
                                ))}
                            </Tabs>
                            {categoryDetails?.map((tab, index) => (
                                <TabPanel
                                    key={index}
                                    value={value}
                                    index={index}
                                    className="tabpanel-full"
                                >
                                    <div className="question-part">
                                        <div className="inline-parts">
                                            <h3>{setDetails.set}</h3>
                                        </div>

                                        {tab.question?.map((key, i) => (
                                            <div key={i}>

                                                <div>
                                                    <div className="flex-preview-tab">
                                                        <p className="preview-question-heading">
                                                            Q {i + 1} :
                                                        </p>
                                                        <span className="data-text-preview">
                                                            {key.text}

                                                            {key.optionType == "MCQ" ? (
                                                                <div>
                                                                    <FormControl>
                                                                        {key.options?.map((option, j) => {
                                                                            return (
                                                                                <FormControlLabel
                                                                                    classes={"checkbox-label"}
                                                                                    key={j}
                                                                                    value={option.title}
                                                                                    control={<Checkbox />}
                                                                                    checked={
                                                                                        key.answer?.includes(option.title) ||
                                                                                        false
                                                                                    }
                                                                                    label={option.title}
                                                                                    onChange={(e) =>
                                                                                        questionnaireAnswerConfig(e, i)
                                                                                    }
                                                                                />
                                                                            );
                                                                        })}
                                                                    </FormControl>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <FormControl>
                                                                        <RadioGroup
                                                                            row
                                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                                            name="row-radio-buttons-group"
                                                                            defaultValue={
                                                                                key.answer?.length ? key.answer[0] : null
                                                                            }
                                                                        >
                                                                            {key.options?.map((option, j) => {
                                                                                return (
                                                                                    <FormControlLabel
                                                                                        key={j}
                                                                                        sx={{ width: "100%" }}
                                                                                        value={option.title}
                                                                                        control={<Radio />}
                                                                                        label={option.title}
                                                                                        onChange={(e) =>
                                                                                            readRadioButton(e, i)
                                                                                        }
                                                                                    />
                                                                                );
                                                                            })}
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </div>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </TabPanel>
                            ))}
                        </Box>
                        <Box style={{ float: "right", marginTop: "-20px" }}
>
                            <Button
                                variant="contained"
                                style={{  marginRight: "20px" }}
                                onClick={() => saveQuestionnaireAnswer(categoryDetails, "save")}
                            >
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                // style={{ float: "right", marginTop: "-20px" }}
                                onClick={() => {
                                    const result = checkMissingAnswer(categoryDetails)
                                    console.log(result)
                                    if (result.missing === true) {
                                        enqueueSnackbar(`Please fill answers for each question in ${result.category}`, {
                                            variant: "error",
                                        });
                                    } else {
                                        setFormData({
                                            ...formData,
                                            categoriesDetails: categoryDetails,
                                        });
                                        setShowPublishForm(true);
                                    }
                                }}
                            >
                                Proceed
                            </Button>
                        </Box>
                    </div>
                </>
            ) : (
                <>
                    <Button
                        variant="contained"
                        sx={{ float: "right", marginBottom: "12px" }}
                        onClick={() => setShowPublishForm(false)}
                    >
                        Back
                    </Button>

                    <form className="profile-form">
                        <Card className="padding-square">
                            <Grid container spacing={2}>
                                {/* Type */}
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                            id="demo-simple-select"
                                            label="Type"
                                            name="type"
                                            value={formData.type}
                                            onChange={(e) =>
                                                setFormData({ ...formData, type: e.target.value })
                                            }
                                        >
                                            <MenuItem value="public">Public</MenuItem>
                                            <MenuItem value="private">Private</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* Category */}
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        {/* <InputLabel id="demo-simple-select-label">Set</InputLabel> */}
                                        <TextField
                                            name="category"
                                            label="Category"
                                            value={formData.setDetails?.set}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* description */}
                                <Grid item xs={12} md={12}>
                                    <TextareaAutosize
                                        fullWidth
                                        aria-label="empty textarea"
                                        placeholder="Description"
                                        minRows={4}
                                        className="fullWidthTextarea"
                                        name="description"
                                        value={formData.description}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                description: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>

                                <div className="btn-arrange">
                                    <Button
                                        variant="contained"
                                        className="click-to-begin m-l-0 cancel-btn"
                                        onClick={() =>
                                            navigate("/third-party/upload-tab", {
                                                state: { tabIndex: location.state.tabIndex },
                                            })
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ color: "white" }}
                                        className="addnew export-btn"
                                        onClick={() => {
                                            checkValidation("excel");
                                        }}
                                    >
                                        Publish as Excel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ color: "white" }}
                                        className="addnew export-btn"
                                        onClick={() => {
                                            checkValidation("pdf");
                                        }}
                                    >
                                        Publish as PDF
                                    </Button>
                                </div>
                            </Grid>
                        </Card>
                    </form>
                </>
            )}
        </>
    );
}

export default QuestionnaireSetAnswer;

