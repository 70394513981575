// @mui
import PropTypes from 'prop-types';
// import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
// import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

// const StyledIcon = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
// }));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  // subtitle: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, subtitle, total, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        // py: 5,
        pb: 4,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <div className='align-boxes'>
        <div className='vw-width'>
          <Typography variant="h4" sx={{opacity: 0.81,textAlign : 'left', paddingLeft: '15px', paddingTop: '50px'}}>
          {title}
          </Typography>
          <Typography variant="h6" sx={{opacity: 0.81,textAlign : 'left', paddingLeft: '15px', }}>
          {subtitle}
          </Typography>
        </div>

       <div className='totalnumber'>
          <Typography variant="h2" sx={{ textAlign: 'right', marginRight: '15px' }} >
           {total !== 0 ? fShortenNumber(total) : 0}
          </Typography>
        </div>
      </div>
    </Card>
  );
}
