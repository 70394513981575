import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../assets/arrow-left-line.svg";
import {
  Link,
  Stack,
  TextField,
  Alert,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PostRequest from "../../../components/apiConnections/postRequest";

export default function ForgetPasswordForm({toggleTheme}) {
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState({});
  const [alertData, setAlertData] = useState({});
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'Dark');

  const toggleModeButtonClick = () => {
    setTheme((theme === 'Dark') ? `Light` : `Dark`)
    toggleTheme();
  };

  useEffect(() => {
    if (alertData.showalert) {
      setTimeout(() => {
        setAlertData({});
      }, 5000);
    }
  }, [alertData]);

  const do_forget = async (e) => {
    e.preventDefault();
    setAlertData({
      alerttype: "info",
      showalert: true,
      message: "Please wait...",
    });
    try {
      const { email } = inputValues;
      if(email === '' || email === undefined)
      {
        setAlertData({
          alerttype: "error",
          showalert: true,
          message: "Please enter valid email address.",
        });
        return false;
      }
      const payload = {
        email: email,
        roleId: 1,
      };
      const userResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_FORGET_PASSWORD_ENDPOINT}`,
        payload
      );
      if (userResult && userResult.status === 200) {
        if (userResult.data.code === 200) {
            setAlertData({
              alerttype: "success",
              showalert: true,
              message: "You will receive an email containing a link to reset the password..",
            });
            setTimeout(() => {
              navigate("/", { replace: true });
            }, 3000);
         }
         else {
            setAlertData({
              alerttype: "error",
              showalert: true,
              message: "Unauthorized user.",
            });
          }
      } else {
        setAlertData({
          alerttype: "error",
          showalert: true,
          message: "Unauthorized user.",
        });
      }
    } 
    catch (error) {
      setAlertData({
        alerttype: "error",
        showalert: true,
        message: "Something went wrong.",
      });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <>
      {alertData.showalert ? (
        <Alert sx={{ mb: 2 }} severity={alertData.alerttype}>
          {alertData.message}
        </Alert>
      ) : null}
      <form onSubmit={(e) => do_forget(e)}>
        <Stack spacing={3}>  
        <div className="inputfield">  
          <TextField
            name="email"
            placeholder="Email address"
            onChange={handleChange}
            /> 
           <img src="/assets/email-icon.svg" alt="logo"/>
          </div>  
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >          
        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" className="space-bottom">
          Confirm
        </LoadingButton>
         <Link href="/" className="achor">
          <h5 className="back-to">
            <img src={BackArrow} alt="icon" /> Back to login
          </h5>
        </Link>
      </form>
      {/* <FormControlLabel className="dark-toggle-forgot" control={<Switch checked={theme === "Dark"} onChange={() => toggleModeButtonClick()} />} label={`${theme} Mode `} /> */}
    </>
  );
}
