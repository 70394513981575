import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./css/Vendors.css";
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
// components
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import Delete from "../assets/delete.svg";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import ModalDialog from "./ModalDialog.js";
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Ecosystems Found</Box>
    </StyledGridOverlay>
  );
}

export default function UserPage() {
  const [vendorsData, setVendorsData] = useState([]);
  const [isNotFound, setisNotFound] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [username, setUserName] = useState(
    window.localStorage.getItem("userEmail")
  );
  const [openUser, setOpenUser] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [userRemoveId, setUserRemoveId] = useState(0);
  const [adminRemoveId, setAdminRemoveId] = useState(0);
  const roleId = parseInt(window.localStorage.getItem("roleId"));
  const [vendorType, setVendorType] = useState([]);
  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([
    { field: "name", headerName: "Name", editable: false, width: 150 },
    { field: "target", headerName: "Target", editable: false, width: 200 },
    {
      field: "risk",
      headerName: "Risk",
      editable: false,
      width: 150,
      renderCell: (params) => {
        const score = params.row.score;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              borderRadius: "3px",
              paddingY: "5px",
              paddingX: "15px",
              backgroundColor: `${
                score <= 25
                  ? "red"
                  : score <= 50
                  ? "blue"
                  : score <= 75
                  ? "#f9a825"
                  : "green"
              }`,
            }}
          >
            {score <= 25
              ? "Critical"
              : score <= 50
              ? "High"
              : score <= 75
              ? "Medium"
              : "Low"}
          </Box>
        );
      },
    },
    { field: "score", headerName: "Score", editable: false, width: 150 },
    { field: "vendorType", headerName: "Type", editable: false, width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
            <Link
              to={`/${roleId === 2 ? "admin" : "dashboard"}/vendorDetails/${
                params.row.target
              }`}
            >
              <Button>
                <VisibilityIcon />
              </Button>
            </Link>
            <Button
              onClick={() => {
                handleOpen(params.row.target, roleId === 2 ? "admin" : "user");
              }}
            >
              <DeleteIcon />
            </Button>
          </Box>
        );
      },
    },
  ]);

  useEffect(() => {
    setOpenUser(userRemoveId !== 0 ? true : false);
  }, [userRemoveId]);

  useEffect(() => {
    setOpenAdmin(adminRemoveId !== 0 ? true : false);
  }, [adminRemoveId]);

  const handleClose = () => {
    setOpenUser(false);
    setOpenAdmin(false);
    setUserRemoveId(0);
    setAdminRemoveId(0);
  };

  const handleOpen = (id, text) => {
    if (text === "user") {
      setUserRemoveId(id);
    } else if (text === "admin") {
      setAdminRemoveId(id);
    }
  };

  const callAPI = async () => {
    await getVendorDetails();
    getVendorsType();
  };

  useEffect(() => {
    callAPI();
  }, []);

  const getVendorsType = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_VENDOR_TYPES}`
        )
          .then((name) => {
            const obj = name.data.data;
            setVendorType(obj);
            setshowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar("Vendor Types not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      enqueueSnackbar("Vendor Types Name not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getVendorDetails = async () => {
    // REACT_APP_VENDORS_LIST
    setshowLoader(true);
    const role = parseInt(window.localStorage.getItem("roleId"));
    const endpoint =
      role === 2
        ? process.env.REACT_APP_VENDORS_LIST
        : process.env.REACT_APP_REQUEST_VENDORS_LIST;

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setisNotFound(!(resVendors.data.data.length > 0));
            setVendorsData(arrResponse);
            setRows(
              arrResponse?.length
                ? [
                    ...arrResponse?.map((d, i) => ({
                      ...d,
                      id: i + 1,
                      name:
                        d.name
                          ?.substring(0, d.name?.indexOf("."))
                          .charAt(0)
                          .toUpperCase() +
                        d.name
                          ?.substring(0, d.name?.indexOf("."))
                          .substr(1)
                          .toLowerCase(),
                      risk:
                        d.score <= 25
                          ? "Critcal"
                          : d.score <= 50
                          ? "High"
                          : d.score <= 75
                          ? "Medium"
                          : "Low",
                      vendorType:
                          d.vendorType || "Vendor"
                    })),
                  ]
                : []
            );
            setshowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setshowLoader(false);
    }
  };

  const removeData = async () => {
    const deleteId = adminRemoveId;
    setshowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = "delete-vendor";
      const payload = {
        vendor: deleteId,
      };
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: "success" });
          setshowLoader(false);
          handleClose();
          setTimeout(() => {
            getVendorDetails();
          }, 1000);
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setshowLoader(false);
        });
    }
  };

  const removeLink = async () => {
    const deleteId = userRemoveId;
    setshowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = "unlinked-vendor";
      const payload = {
        username,
        vendor: deleteId,
      };
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: "success" });
          console.log(resVendors.data);
          setshowLoader(false);
          handleClose();
          setTimeout(() => {
            getVendorDetails();
          }, 1000);
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setshowLoader(false);
        });
    }
  };

  const ModalContentAdmin = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to remove vendor ?
        </h2>
      </Typography>
    );
  };

  const ModalContentUser = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to remove vendor ?
        </h2>
      </Typography>
    );
  };

  return (
    <>
      <Loader show={showLoader} />

      <Helmet>
        <title> Ecosystems | Genesis Platform</title>
      </Helmet>

      <Container maxWidth={false}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={4}
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            <span className="heading-main"> Ecosystems </span>
          </Typography>
        </Stack>

        {showLoader === true ? (
          <Loader show={showLoader} />
        ) : (
          <Card className="full-coverdesign vendor-table-card margin-ecosystem">
            {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
            <div className="cover-table">
              <h3 className="vendor-head">All ecosystem detail</h3>
            </div>
            <DataGrid
              sx={{ border: "none", height: "409px", width: "100%" }}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterValues: [],
                  },
                },
              }}
              autosizeOptions={{
                includeOutliers: true,
                includeHeaders: false,
              }}
              pageSizeOptions={[5, 10, 25]}
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true },
                },
              }}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
            />
          </Card>
        )}
        <ModalDialog
          handleClose={handleClose}
          open={openAdmin}
          onSubmit={removeData}
          contentData={ModalContentAdmin}
        />
        <ModalDialog
          handleClose={handleClose}
          open={openUser}
          onSubmit={removeLink}
          contentData={ModalContentUser()}
        />
      </Container>
    </>
  );
}
