import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import PostRequest from "../../components/apiConnections/postRequest";
import Loader from "../../Loader";
import { useTheme } from "@emotion/react";

const AddInfo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const myObject = location.state ? location.state.data : null;
  const tabIndex = location.state ? location.state.tabIndex : 0;
  const [categoryId, setCategoryId] = useState(myObject ? myObject._id : null);
  const [showLoader, setShowLoader] = useState(false);
  const [data, setData] = useState({
    category: "",
    subCategory: [
      {
        title: "",
        description: "",
      },
    ],
  });

  const [expandedIndex, setExpandedIndex] = useState(null);
  const theme = useTheme();
  const handleAccordionChange = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  useEffect(() => {
    if (categoryId && myObject) {
      const { ...rest } = myObject;

      setData({
        ...rest,
      });
    }
  }, [categoryId, myObject]);

  const handleChange = (field, value, subCatIndex = null) => {
    setData((prevFormData) => {
      if (subCatIndex !== null) {
        const updatedSubCategory = [...prevFormData.subCategory];
        updatedSubCategory[subCatIndex] = {
          ...updatedSubCategory[subCatIndex],
          [field]: value,
        };

        return {
          ...prevFormData,
          subCategory: updatedSubCategory,
        };
      } else {
        return {
          ...prevFormData,
          [field]: value,
        };
      }
    });
  };

  const handleAddSubCategory = () => {
    setData((prevData) => ({
      ...prevData,
      subCategory: [
        ...prevData.subCategory,
        {
          title: "",
          description: "",
        },
      ],
    }));
  };

  const handleRemoveSubCategory = (subCatIndex) => {
    setData((prevData) => {
      const updatedSubCategory = [...prevData.subCategory];
      updatedSubCategory.splice(subCatIndex, 1);

      return {
        ...prevData,
        subCategory: updatedSubCategory,
      };
    });
  };

  const uploadCategory = async () => {
    setShowLoader(true);
    let formData = new FormData();
    if (categoryId) formData.append("categoryId", categoryId);
    formData.append("emailId", localStorage.getItem("userEmail"));
    formData.append("category", data.category);
    formData.append("subCategory", JSON.stringify(data.subCategory));

    const endpoint = process.env.REACT_APP_UPLOAD_THIRD_PARTY_CATEGORY;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          formData
        )
          .then((res) => {
            enqueueSnackbar(res.data.message, { variant: "success" });
            setShowLoader(false);
            navigate("../upload-tab", { state: { tabIndex: tabIndex } });
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const checkValidation = () => {
    uploadCategory();
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Typography variant="h4" sx={{ mb: 2, mt: 5 }}>
        <span className="heading-main">Other Information</span>
      </Typography>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          className="m-l-0 click-to-begin"
          onClick={() =>
            navigate("../upload-tab", { state: { tabIndex: tabIndex } })
          }
        >
          Back
        </Button>
      </div>
      {/* {invalidComplianceId ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography>Invalid Compliance</Typography>
        </Box>
      ) : ( */}
      <Grid container spacing={2} pb={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{ mb: 5, mt: 2 }} className="full-form">
            {/* <Link to={`../questionnaire-library`}>
              <Button
                variant="contained"
                className="click-to-begin m-l-0 cancel-btn"
              >
                Back
              </Button>
            </Link> */}
            <form action="" className="full-form">
              <Grid container spacing={2} pb={2}>
                {/* Category */}
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl fullwidth="true">
                    <Typography
                      variant="h6"
                      sx={{ mb: 2 }}
                      className="title-label"
                    >
                      Category
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        gap: "5px",
                      }}
                    >
                      <div style={{ width: "80%" }}>
                        <TextField
                          variant="outlined"
                          name="category"
                          value={data.category}
                          placeholder="Category"
                          onChange={(e) =>
                            handleChange("category", e.target.value)
                          }
                        />
                      </div>
                      <Button
                        variant="contained"
                        className="next-button min-h40"
                        onClick={handleAddSubCategory}
                        sx={{ mb: 2 }}
                      >
                        Add Info
                      </Button>
                    </div>
                  </FormControl>
                </Grid>
              </Grid>

              {data.subCategory.map((subCat, subCatIndex) => (
                <div key={subCatIndex} className="accordion-part">
                  <Accordion
                    sx={{ mb: 2 }}
                    className="accordion"
                    expanded={subCatIndex === expandedIndex}
                    onChange={() => handleAccordionChange(subCatIndex)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`section${subCatIndex}-content`}
                      id={`section${subCatIndex}-header`}
                    >
                      {/* {subCatIndex === expandedIndex ? <ExpandLessIcon sx={{ fontSize: "34px" }} /> : <ExpandMoreIcon sx={{ fontSize: "34px" }} />} */}
                      <span
                        style={{
                          fontSize: "18px",
                          color:
                            theme.palette.mode === "dark" ? "#fff" : "#242526",
                        }}
                      >
                        Info - {subCatIndex + 1}
                      </span>

                      <IconButton
                        onClick={() => handleRemoveSubCategory(subCatIndex)}
                        sx={{ ml: "auto" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </AccordionSummary>

                    <AccordionDetails>
                      {/* Title */}
                      <FormControl fullwidth="true" sx={{ mb: 2 }}>
                        <Typography
                          variant="h6"
                          sx={{ mb: 2 }}
                          className="title-label"
                        >
                          Title
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullwidth="true"
                          name="title"
                          value={subCat.title}
                          placeholder="Title"
                          onChange={(e) =>
                            handleChange("title", e.target.value, subCatIndex)
                          }
                        />
                      </FormControl>

                      {/* Description */}
                      <FormControl fullwidth="true" sx={{ mb: 2 }}>
                        <Typography
                          variant="h6"
                          sx={{ mb: 2 }}
                          className="title-label"
                        >
                          Description
                        </Typography>
                        <TextareaAutosize
                          name="description"
                          value={subCat.description}
                          fullwidth="true"
                          aria-label="empty textarea"
                          placeholder="Description"
                          minRows={4}
                          className="fullWidthTextarea"
                          onChange={(e) =>
                            handleChange(
                              "description",
                              e.target.value,
                              subCatIndex
                            )
                          }
                        />
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}

              <div className="btn-arrange">
                <Button
                  variant="contained"
                  className="click-to-begin m-l-0 cancel-btn"
                  onClick={() =>
                    navigate("../upload-tab", { state: { tabIndex: tabIndex } })
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: "white" }}
                  className="addnew export-btn"
                  onClick={() => {
                    checkValidation();
                  }}
                >
                  Upload
                </Button>
              </div>
            </form>
          </Card>
        </Grid>
      </Grid>
      {/* )} */}
    </>
  );
};

export default AddInfo;
