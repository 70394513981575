import { useTheme } from "@emotion/react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  Collapse,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const OtherInfoSection = (props) => {
  const theme = useTheme();
  const { data, handleDrawerClose, triggerIndex } = props;
  const scrollToRef = useRef(null);
  const [open, setOpen] = useState({});

  useEffect(() => {
    // Scroll to the specified ref when the drawer is opened
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    if (triggerIndex !== null) {
      const triggeredItem = document.getElementById(`infoItem-${triggerIndex}`);
      if (triggeredItem) {
        triggeredItem.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [scrollToRef, triggerIndex]);

  const handleToggle = (index) => {
    setOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      <Container
        maxWidth={false}
        className={theme.palette.mode === "light" ? "light-mode-class" : "dark-mode-class"}
        style={{ paddingBottom: "18px" }}
      >
        <Box
          sx={{
            mt: 2,
            mb: 3,
            // borderBottom: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            <span>Other Info</span>
          </Typography>
          <ClearIcon onClick={handleDrawerClose} sx={{ cursor: "pointer" }} />
        </Box>
        <Grid container spacing={3} sx={{ padding: 0 }}>
        {data?.length <= 0 && (
            <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "26px",
              width: "100%",
            }}
          >
            <p style={{marginTop: "250px"}}>No Record Found</p>
          </div>
          )}
          {data.map((infoItem, index) => (
            <Grid item xs={12} md={12}>
              <Paper
                elevation={3}
                id={`infoItem-${index}`}
                sx={{
                  padding: "12px",
                }}
                className={triggerIndex === index ? "active-border-line" : ""}
                key={infoItem._id}
                ref={index === triggerIndex ? scrollToRef : null}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="ISO-private">
                      <h3>{infoItem.category}</h3>
                    </div>
                  </div>
                  <div>
                    {infoItem.subCategory.map((item, index) => (
                      <>
                        <List>
                          <ListItemButton
                            onClick={() => {
                              handleToggle(item._id);
                            }}
                            sx={{ paddingLeft: 0 }}
                          >
                            <ListItem sx={{ paddingLeft: 0 }}>
                              {`${index + 1})`} {item.title}
                            </ListItem>
                            {open[item._id] ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                        </List>
                        <Collapse
                          in={open[item._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <ListItem sx={{ padding: 0 }}>
                              <div className="ISO-private">
                                <p
                                  className="discription-text"
                                  style={{
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {item.description}
                                </p>
                              </div>
                            </ListItem>
                          </List>
                        </Collapse>
                      </>
                    ))}
                  </div>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default OtherInfoSection;
