import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Alert,
  Card,
  Button,
  Snackbar,
  Slide,
  Fade,
  Grid
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import PostRequest from "../../../components/apiConnections/postRequest";
// ----------------------------------------------------------------------
import { getLocalStoarge } from "../../../components/common/allFunctions";
import { SnackbarProvider, useSnackbar } from 'notistack';

import ChangePassward from "../../../assets/change-passward.svg";
import Loader from "../../../Loader";


export default function ChangePasswordForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [inputValues, setInputValues] = useState({});
  const [alertData, setAlertData] = useState({});
  const [showLoader, setshowLoader] = useState(false);


  function SlideTransition(props) {
    return <Slide {...props} direction="up"  />;
  }
  const [state, setState] = useState({
    open: false,
    Transition: Fade,
    });

  const handleClick = (Transition) => () => {
    setState({
      open: true,
      Transition,     
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  useEffect(() => {
    
  }, []);

  const do_update = async (e) => {
    e.preventDefault();
    setshowLoader(true);            
  
    try {
      const { oldPassword,newPassword,confirmPassword } = inputValues;
      if(oldPassword === '' || oldPassword === undefined || newPassword === '' || newPassword ===  undefined ||
      confirmPassword === '' || confirmPassword ===  undefined )
      {
        enqueueSnackbar('Please fill all field.', { variant : 'error' });  
        setshowLoader(false);           
        return false;        
      }
      const payload = {
        oldPassword, 
        newPassword,
        confirmPassword
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
       );
       if(refreshToken)
       {       
        const userResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CHANGE_PASSWORD_ENDPOINT}`,
          payload,
        );
        if (userResult && userResult.status === 200) {
          if (userResult.data.code === 200) {
            enqueueSnackbar("Password changed successfully.", { variant : 'success' });   
            setshowLoader(false);                  
            setInputValues({});
              setTimeout(() => {
                navigate("/", { replace: true });
              }, 2000);
            }
          else {
            enqueueSnackbar(userResult.data.message, { variant : 'error' });     
            setshowLoader(false);           
            }
        } 
        else {
          enqueueSnackbar(userResult.data.message, { variant : 'error' });     
           setshowLoader(false);            
          }
       } 
    } 
    catch (error) {
      enqueueSnackbar("Password not changed.", { variant : 'error' });     
      setshowLoader(false);            

     }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <>
      <Loader show={showLoader}/>
       <form onSubmit={(e) => do_update(e)} className="change-forms">
        <Stack spacing={3}> 
          <img src={ChangePassward} className="icon-s"  alt="logo" />
          <h4 className='heading-main center'>Change your password</h4>
        <Grid item xs={12} md={12} lg={12}>
        <TextField
            name="oldPassword"
            // label="Old Password"
            placeholder="Old Password"
            type={showOldPassword ? "text" : "password"}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    edge="end" className="eye-part">
                    <Iconify
                      icon={showOldPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            />  
          </Grid> 
          <Grid item xs={12} md={12} lg={12}>
        <TextField
            name="newPassword"
            placeholder="New password"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end" className="eye-part"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
           <TextField
            name="confirmPassword"
            // label="Confirm Password"
            placeholder="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end" className="eye-part"
                  >
                    <Iconify
                      icon={showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            />   
          </Grid>  
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
        </Stack>
        <LoadingButton  size="small" type="submit" variant="contained" color="success" className="update-button">
          Update
        </LoadingButton>
       </form>
    </>
  );
}
