import axios from "axios";
import { setLocalStoarge, getLocalStoarge, removeLocalStoarge } from "../common/allFunctions";
async function PutRequest(session_url, formData, token, type) {
  const role = parseInt(window.localStorage.getItem('roleId'));
  let saved = window.localStorage.getItem('R4');

  const initialValue = JSON.parse(saved);
  const Token = (initialValue)?.accessToken;
  let twoFa = (initialValue)?.twofa;
  let profile_Pic = (initialValue)?.profilePic;
  // let expire_In = 3600000;
  let config = {
    headers: { Authorization: `Bearer ${Token}` }
  };
  let payload = formData;
  if (type == "refreshToken") {
    payload = {
      "refreshToken": initialValue.refreshToken
    }

  } else if (type == "ImageUpload") {
    config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        Accept: `*/*`,
        'content-type': 'multipart/form-data'
      }
    }
  }

  try {
    return new Promise((resolve, reject) => {
      axios
        .put(session_url, payload, config)
        .then((getResponse) => {

          if (type == "refreshToken") {
            var validity = check_time();
            if (!validity) {
              removeLocalStoarge();
              window.location.href = "/";
              resolve(false);
              return;
            }

            if (getResponse.status === 200 && getResponse.data.code === 200) {
              const { accessToken, refreshToken, userTime } = getResponse.data.data;
              const logged_user = {
                accessToken,
                refreshToken,
                twofa: twoFa,
                profilePic: profile_Pic
              };
              setLocalStoarge("usertime", userTime);
              // localStorage.setItem("usertime", new Date().getTime() + parseInt(expire_In));
              if (role === 2) {
                setLocalStoarge("R2", JSON.stringify(logged_user));
                // localStorage.setItem("R2", JSON.stringify(logged_user));
              } else {
                setLocalStoarge("R1", JSON.stringify(logged_user));
                // localStorage.setItem("R1", JSON.stringify(logged_user));
              }
              resolve(true);
            } else {
              resolve(false);
              removeLocalStoarge();
              window.location.href = "/";
            }
          } else {
            resolve(getResponse);
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response?.data?.message) {
            reject({ message: error.response?.data?.message })
          }
          else if (error.response) {
            reject(error.message)
          }
        });
    })
  } catch (error) {
    console.log("error ==>", error);

  }
}
const check_time = () => {
  var expired_time = parseInt(process.env.REACT_APP_CURENT_TIME)  //60 minutes default
  let time_date = getLocalStoarge('usertime');
  const DT_1 = time_date;
  const DT_2 = new Date().getTime();
  var diff = DT_2 - DT_1;
  var minutes_diff = (diff / (1000 * 60)).toFixed(0);
  if (minutes_diff >= expired_time) {
    return false;
  } else {
    return true;
  }
};
export default PutRequest;
