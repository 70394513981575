import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import {useState, useEffect} from 'react';
// @mui
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
import GaugeChart from 'react-gauge-chart'
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';
import PostRequest from "../../../components/apiConnections/postRequest"
import { SnackbarProvider, useSnackbar } from 'notistack';
import Loader from '../../../Loader';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  }, 
  text: {
  visibility: 'hidden!important'
  },

}));

// ----------------------------------------------------------------------

AppOverallScore.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppOverallScore({ title, subheader, riskScore, ...other }) {
  const theme = useTheme();
  const riskData = Number(riskScore) / 100;
  const [alertData, setAlertData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [themename, setThemeName] = useState((window.localStorage.getItem('theme')==='Dark') ? 'Dark' : 'Light');
  const [Overallscore, setOverallscore] = useState(riskData);
  const userEmail = window.localStorage.getItem("userEmail");
  
  const [option, setOption] = useState(7);
  const handleChange = (event) => {
    setOption(event.target.value);
  };


  const updateOverallscore = async () =>{
    setshowLoader(true);
    const payload = {
      username : userEmail,
      selectedDays : option
    }
    const endpoint = process.env.REACT_APP_OVERALL_SCORE_DASHBOARD;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
       ); 
       if(refreshToken)
       {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`, payload
        )
        .then((resOverallscore)=>{
          const response = resOverallscore.data.data;
          const score = Number(response.overall_score) / 100;
          setOverallscore(score);   
          setshowLoader(false);

        })
        .catch((errOverallscore)=>{
          enqueueSnackbar(errOverallscore.message, { variant : 'error' });      
          setshowLoader(false);

        })
        }
      else
      {
        setshowLoader(false);
        window.location.href = "/";
      }
    }  
    catch(error)
    {
      enqueueSnackbar(error.message, { variant : 'error' }); 
      setshowLoader(false);

     }  
  }

  useEffect(()=>{
    const themeName = localStorage.getItem('theme');
    setThemeName(themeName);
  }, [])

  useEffect(()=>{
    updateOverallscore();
  }, [option])
  return (
    <>
    <Loader show={showLoader}/>
    <Card {...other}>
      <div className='card-headerpart'>
        <CardHeader  title={title} subheader={subheader} sx={{marginLeft : '0px', fontSize: '1.0rem'}} />
       
      </div>
      <StyledChartWrapper dir="ltr" sx={{mt:0}}>
           <GaugeChart id="gauge-chart3" 
          nrOfLevels={3} 
          colors={["#d32f2f", "#ed6c02", "#2e7d32"]}
          arcWidth={0.1} 
          arcPadding={0.05}
          cornerRadius={5} 
          needleColor = {(Overallscore>=0.66) ? "#2e7d32" : (Overallscore>=0.33 && Overallscore<0.66) ? "#ed6c02" : "#d32f2f"}
          needleBaseColor = {"rgb(144, 202, 249)"}
          textColor = {(Overallscore>=0.66) ?  "#2e7d32" : (Overallscore>=0.33 && Overallscore<0.66) ? "#ed6c02" : "#d32f2f"}
          percent={Overallscore} 
          // arcsLength={[0.3, 0.5,0.2]}
        />     
        <span className='percentText' style={{color:(Overallscore>=0.66) ? "#2e7d32" : (Overallscore>=0.33 && Overallscore<0.66) ? "#ed6c02" : "#d32f2f"}}>{Math.round(Overallscore*100)}<small  className='textSize'>/100</small></span>
        {/* <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={280} /> */}
      </StyledChartWrapper>
    </Card>
    </>
  );
}
