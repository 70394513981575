import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './Loader.css';

export default function Loader({show, styleChange}) {
  const loaderStyle = {
    position: styleChange ? 'fixed' : '', // Set position to fixed if yes is true
  };
  return (
    (show === true) ?
        <div className="loader" show style={loaderStyle}>
         <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
        </div>
        : null
  )
}
