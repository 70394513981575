import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ isLoggedIn, children }) => {
  // const isAuthenticated = JSON.parse( localStorage.getItem("R1"));
  // const isAuthenticatedRoleId = JSON.parse( localStorage.getItem("roleId"));

  return ( 
    isLoggedIn === true ? children :<Navigate to="/" replace/> 
  );
}


export default ProtectedRoute;