import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  InputAdornment, Stack, TextField
} from "@mui/material";
// components
import { useSnackbar } from 'notistack';
import fullLight from '../../../assets/full-light.png';
import PostRequest from "../../../components/apiConnections/postRequest";
import Iconify from "../../../components/iconify";
import Loader from "../../../Loader";
// ----------------------------------------------------------------------

export default function ResetPasswordForm(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [inputValues, setInputValues] = useState({});
  const [alertData, setAlertData] = useState({});

  const [token, setToken] = useState(props.urlToken.token);
  const [showLoader, setshowLoader] = useState(false);

  useEffect(() => {
    if (alertData.showalert) {
      setTimeout(() => {
        setAlertData({});
      }, 3000);
    }
  }, [alertData]);

  const do_forget = async (e) => {
    e.preventDefault();
    setAlertData({
      alerttype: "info",
      showalert: true,
      message: "Please wait...",
    });
    try {
      const { password, confirm_password } = inputValues;
      const payload = {
        password,
        confirmPassword: confirm_password,
        user_token: token
      };
      const userResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RESET_PASSWORD_ENDPOINT}`,
        payload
      );
      if (userResult && userResult.status === 200) {
        if (userResult.data.code === 200) {
          enqueueSnackbar("Password reset successfully", { variant: 'success' });
          setshowLoader(false);
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 2000);
        }
        else {
          enqueueSnackbar(userResult.data.message, { variant: 'error' });
          setshowLoader(false);
        }
      } else {
        enqueueSnackbar(userResult.data.message, { variant: 'error' });
        setshowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar('Something went wrong.', { variant: 'error' });
      setshowLoader(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <>
      <Loader show={showLoader} />
      <form onSubmit={(e) => do_forget(e)} className="relative">
        <Stack spacing={5}>
          <img src={fullLight} className="logo-size" alt="icon" />
          <h2 className="enter-new-password">Enter New Password</h2>
          <TextField
            name="password"
            // label="Password"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="eye-icon">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="confirm_password"
            // label="Confirm Password"
            placeholder="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="eye-icon">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Save
        </LoadingButton>
      </form>
    </>
  );
}
