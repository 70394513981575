import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import GetRequest from "../../components/apiConnections/getRequest";
import PostRequest from "../../components/apiConnections/postRequest";
import Loader from "../../Loader";
import ModalDialog from "../ModalDialog";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Requests Found</Box>
    </StyledGridOverlay>
  );
}

const Requests = () => {
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [status, setStatus] = useState(null);
  const [rows, setRows] = useState([]);
  const sortedRows = [...rows].sort((a, b) => {
    // Custom sorting logic
    const statusOrder = { Requested: 1, Declined: 2, Approved: 3 }; // Adjust as needed
    return statusOrder[a.status] - statusOrder[b.status];
  });
  const [columns, setColumns] = useState([
    { field: "id", headerName: "No.", editable: false, width: 50 },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      width: 200,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      width: 250,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "requestedFileType",
      headerName: "File",
      editable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status;

        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              borderRadius: "3px",
              paddingY: "5px",
              paddingX: "15px",
              backgroundColor: `${
                status === "Requested"
                  ? "#f9a825"
                  : status === "Declined"
                  ? "red"
                  : "green"
              }`,
            }}
          >
            {status}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return params.row.status !== "Approved" &&
          params.row.status !== "Declined" ? (
          <Box className="mui-icons-btn icons-align">
            <Tooltip title="Approve" placement="top" arrow>
              <Button
                onClick={() => {
                  setShowApproveModal(true);
                  setUserId(params.row._id);
                  setEmail(params.row.email);
                  setFileId(params.row.requestedFileId);
                  setStatus("approve");
                }}
                sx={{ color: "#4caf50" }}
              >
                <DoneIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Decline" placement="top" arrow>
              <Button
                onClick={() => {
                  setShowDeclineModal(true);
                  setUserId(params.row._id);
                  setEmail(params.row.email);
                  setFileId(params.row.requestedFileId);
                  setStatus("decline");
                }}
                sx={{ color: "#f44336" }}
              >
                <CloseIcon />
              </Button>
            </Tooltip>
          </Box>
        ) : (
          "N/A"
        );
      },
    },
  ]);
  const [showLoader, setShowLoader] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getRequestList();
  }, []);

  const getRequestList = async () => {
    setShowLoader(true);
    const endpoint = process.env.REACT_APP_GET_THIRD_PARTY_FILE_REQUEST;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          {}
        )
          .then((res) => {
            if (res.data.code === 200) {
              let arrResponse = res.data.data;
              setRows(
                arrResponse?.length
                  ? [
                      ...arrResponse?.map((d, i) => ({
                        ...d,
                        id: i + 1,
                        name: `${d.firstName} ${d.lastName}`,
                        requestedFileType: d.requestedFileType
                          .replace(/([A-Z])/g, " $1")
                          .trim()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" "),
                      })),
                    ]
                  : []
              );
            } else {
              // enqueueSnackbar(res.data.message, { variant: "error" });
            }
            setShowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const ApprovePrivateFileRequest = async () => {
    setShowLoader(true);
    const endpoint =
      process.env.REACT_APP_APPROVE_THIRD_PARTY_REQUEST_PRIVATE_FILE;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          {
            userId: userId,
            email: email,
            requestedFileId: fileId,
            status: status,
          }
        )
          .then((res) => {
            let arrResponse = res.data.data;
            // console.log(arrResponse);
            getRequestList();
            setShowApproveModal(false);
            setShowDeclineModal(false);
            setShowLoader(false);
            enqueueSnackbar(`${res.data.message}`, {
              variant: "success",
            });
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setShowApproveModal(false);
            setShowDeclineModal(false);
            setShowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const approveModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <h3 style={{ wordBreak: "break-word" }}>Approve Request ?</h3>
      </Typography>
    );
  };

  const declineModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <h3 style={{ wordBreak: "break-word" }}>Decline Request ?</h3>
      </Typography>
    );
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false}>
        
          <Typography variant="h4" sx={{ mt: 4, mb: 5 }}>
            <span className="heading-main">Requests</span>
          </Typography>
          <Stack
            direction="row"
            alignItems="end"
            justifyContent="end"
            mb={0}
          ></Stack>

        <Card className="full-coverdesign margin-questionnaire">
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          <div className="cover-table">
            <Box
              className="bg-dark-assessment"
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                border: "none",
                borderRadius: "15px",
              }}
            >
              <div style={{ width: "100%" }}>
                <DataGrid
                  sx={{ border: "none", height: "400px", width: "100%" }}
                  rows={sortedRows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  autosizeOptions={{
                    columns: ["id", "firstName", "lastName"],
                    includeOutliers: true,
                    includeHeaders: false,
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                    },
                  }}
                  autoHeight
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                />
              </div>
            </Box>
          </div>
        </Card>
      </Container>
      <ModalDialog
        handleClose={() => setShowApproveModal(false)}
        open={showApproveModal}
        onSubmit={() => ApprovePrivateFileRequest()}
        contentData={approveModalContent}
      />
      <ModalDialog
        handleClose={() => setShowDeclineModal(false)}
        open={showDeclineModal}
        onSubmit={() => ApprovePrivateFileRequest()}
        contentData={declineModalContent}
      />
    </>
  );
};

export default Requests;
