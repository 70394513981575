import CryptoJS from 'crypto-js';

export const getLocalStoarge = (key_id) =>{
    let values = window.localStorage.getItem(key_id);
    values = (values)?values: false;
    return values;
  }
  
  export const setLocalStoarge = (key_id, items) =>{
    window.localStorage.setItem(key_id, items);
    return true;
  }
  
  export const removeLocalStoarge = () =>{
    window.localStorage.clear();
    return true;
  }
  
  export const removeLocalStoargeKey = (key_id) =>{
    window.localStorage.removeItem(key_id);
    return true;
  }
  
  export const encryptFunction = (data)=>{
    const secret = process.env.REACT_APP_SECRET_KEY;
    const encryptedData  = CryptoJS.AES.encrypt(data, secret).toString();
    return encryptedData;
  }
  
  export const decryptFunction =(data)=>{
    const secret = process.env.REACT_APP_SECRET_KEY;
    const bytes = CryptoJS.AES.decrypt(data, secret);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }