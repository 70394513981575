// import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useChart } from "../../components/chart";

function SynchronizedCharts({ seriesData, categoriesData, isLoading }) {
  const chartOptions1 = useChart({
    chart: {
      id: "chart1",
      group: "social",
      type: "line",
      background: "transparent",
    },
    colors: ["#4A10A4"],
    series: [
      {
        name: "Total Views",
        data: seriesData,
      },
    ],
    xaxis: {
      categories: categoriesData,
      lines: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        minWidth: 10,
      },
      lines: {
        show: false,
      },
    },
    toolbar: {
      show: false,
    },
  });

  if (!seriesData || seriesData.length === 0) {
    // Render a message when there's no data
    return <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
    }}
  >
    <p>No Record Found</p>
  </div>;
  }
  return (
    <div style={{ width: "100%" }}>
        <ReactApexChart
          options={chartOptions1}
          series={chartOptions1.series}
          type="area"
          height={350}
          width="100%"
          id="chart1"
        />
    </div>
  );
}

export default SynchronizedCharts;
