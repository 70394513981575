import {
  Box, Button, FormControl, Grid, InputLabel,
  MenuItem, Modal, Select, Typography, Card, CardContent
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import GetRequest from "../../components/apiConnections/getRequest";
import PostRequest from "../../components/apiConnections/postRequest";
import QuestionnaireImage from '../../assets/questionnaire-image.png';
import '../../pages/css/questionnaire-cards.css';

const CustomQuestionnaireModal = ({ handleClose, open, tabIndex }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [showLoader, setShowLoader] = useState(false);
  const [setList, setSetList] = useState([])
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    maxHeight: 500,
    height: 'auto',
    bgcolor: "background.paper",
    boxShadow: 24,
    textAlign: "center",
    borderRadius: "15px",
    p: 4,
    overflowY: "auto",
  };


  useEffect(() => {
    getSetListByThirdParty()
  }, [])
  const getSetListByThirdParty = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_TP_SETS}`,
          {}
        )
          .then((res) => {
            if (res?.data?.code === 200) {
              setShowLoader(false);
              setSetList(res.data?.data)
            } else {
              enqueueSnackbar(res.data?.message, { variant: "warning" });
              setShowLoader(false);
            }
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setShowLoader(false);
    }
  };
  const handleSetSelected = async (set) => {
    setShowLoader(true);
    // call third party questionnaire answer api and create entry
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const payload = {
        setId: set._id,
        setType: set.type,
        category: set.set,
        logo: set.logo || "",
      }
      PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_TP_QUESIONNAIRE_TO_ANSWER}`,
        payload
      )
        .then((res) => {
          if (res?.data?.code === 200) {
            setShowLoader(false);
            navigate(`/third-party/questionnaire-set-answer`, {
              state: { data: set, tabIndex: 1 },
            })
          } else {
            enqueueSnackbar(res.data?.message, { variant: "warning" });
            setShowLoader(false);
          }
        })
        .catch((err) => {
          enqueueSnackbar(err.message, { variant: "error" });
          setShowLoader(false);
        });
    } else {
      setShowLoader(false)
    }
  }
  return (
    <div>
      <Loader show={showLoader} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={` ${open ? 'blur-background' : ''} ${theme === "Dark" ? 'dark-modal' : 'light-modal'} modal-popup`}
      >
        <Box sx={style}>
          <Box>
            <Typography>Select Set</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: 'center' }} >
              {setList?.length ? setList.map((set, i) => {
                return (
                  <Card raised={true} sx={{ width: "120px", height: "180px", margin: "20px" }} className="questionnaire-cards" onClick={() => handleSetSelected(set)}>
                    <CardContent>
                      <Box>
                        <div className="set-image-container">
                          {set.logo ? set.type === "custom" ?
                            <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_QUESTIONNAIRE_LOGO}/${set?.logo}`} alt="logo" />
                            :
                            <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_ADMIN_QUESTIONNAIRE_LOGO}/${set?.logo}`} alt="logo" />
                            : <img src={QuestionnaireImage} alt="logo" />
                          }
                        </div>
                        <Box className="questionnaire-set-name">{set.set}</Box>
                      </Box>
                    </CardContent>
                  </Card>
                )

              }) : (
                <div>
                  <p>No Sets Found </p>
                </div>
              )}
            </Box>
          </Box>
          <Grid item xs={12} md={12} lg={12} className="center-vendor">
            <Button
              variant="contained"
              className="click-to-begin m-l model-btns cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomQuestionnaireModal;
