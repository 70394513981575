import axios from "axios";
import { setLocalStoarge, getLocalStoarge, removeLocalStoarge } from "../common/allFunctions";
import jwtDecode from "jwt-decode";

async function PostRequest(session_url, formData, token, type) {
  const role = parseInt(window.localStorage.getItem('roleId'));
  let saved=window.localStorage.getItem('R4');
  const initialValue = JSON.parse(saved);
  const Token = (initialValue)?.accessToken;
  let twoFa = (initialValue)?.twofa;
  let profile_Pic = (initialValue)?.profilePic;
  // let expire_In = 3600000;
  let config = {
    headers: { Authorization: `Bearer ${Token}` }
  };
  let payload = formData;
  if (type == "refreshToken") {

    try{
      if (Token && Date.now() < jwtDecode(Token)?.exp * 1000) return true;
    }catch(err){
      console.log(err);
    }
    payload = {
      "refreshToken": initialValue.refreshToken
    }

  }else if(type == "ImageUpload"){
    config = { 
        headers: {
          Authorization: `Bearer ${Token}`,
          Accept: `*/*`,
          'content-type': 'multipart/form-data'           
      }
    }
  }

  try {
    return new Promise((resolve, reject) => {
      axios
        .post(session_url, payload, config)
        .then((getResponse) => {

          if (type == "refreshToken") {
              // var validity = check_time();
              // if (!validity) {
              //   removeLocalStoarge();
              //   window.location.href = "/";
              //   resolve(false);
              //   return;
              // }
       
              if (getResponse.status === 200 && getResponse.data.code === 200) {
                const { accessToken, refreshToken, userTime } = getResponse.data.data;
                const logged_user = {
                  accessToken,
                  refreshToken,
                  twofa :twoFa,
                  profilePic : profile_Pic
                };
                setLocalStoarge("usertime", userTime);            
                if (role === 4) {
                  setLocalStoarge("R4", JSON.stringify(logged_user));                  
                }
                resolve(true);
              } else {
                resolve(false);
                window.localStorage.removeItem('roleId');
                window.localStorage.removeItem('R4');
                window.localStorage.removeItem('usertime');
                window.localStorage.removeItem('userDetails');
                window.localStorage.removeItem('deleteFunction');
                window.location.href = "/";
              }
          } else {
            resolve(getResponse);            
          }
        })
        .catch((error) => {
          if (type == "refreshToken") {
            window.localStorage.removeItem('R4');
            window.location.href = "/";
          }
          if (error.response) {
            if(error.response?.data?.message)  reject(error.response?.data)
            else reject(error.message)
          }
        });
    })
  } catch (error) {
    if (type == "refreshToken") {
      window.localStorage.removeItem('R4');
      window.location.href = "/";
    }
    console.log("error ==>", error);
  }
}
// const check_time = () => {
//   var expired_time= parseInt(process.env.REACT_APP_CURENT_TIME)  //60 minutes default
//   let time_date = getLocalStoarge('usertime');
//   const DT_1 = time_date;
//   const DT_2 = new Date().getTime();
//   var diff = DT_2 - DT_1;
//   var minutes_diff = (diff / (1000*60)).toFixed(0);
//   if (minutes_diff>=expired_time){
//     return false;
//   } else {
//     return true;
//   }
// };
export default PostRequest;
