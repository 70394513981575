import { Drawer } from "@mui/material";
import ComplianceSection from "./ComplianceSection";
import DocumentsSection from "./DocumentsSection";
import OtherInfoSection from "./OtherInfoSection";
import OverviewSection from "./OverviewSection";
import QuestionnairesSection from "./QuestionnairesSection";

export const DrawerContent = (props) => {
  const {
    currentSection,
    overviewData,
    complianceData,
    questionnaireData,
    documentsData,
    otherInfoData,
    isDrawerOpen,
    handleDrawerClose,
    triggerIndex,
  } = props;

  const renderSectionContent = () => {
    switch (currentSection) {
      case "overview":
        return (
          <OverviewSection
            data={overviewData}
            handleDrawerClose={handleDrawerClose}
          />
        );
      case "complianceAndAudits":
        return (
          <ComplianceSection
            data={complianceData}
            handleDrawerClose={handleDrawerClose}
            triggerIndex={triggerIndex}

          />
        );
      case "questionnaires":
        return (
          <QuestionnairesSection
            data={questionnaireData}
            handleDrawerClose={handleDrawerClose}
            triggerIndex={triggerIndex}

          />
        );
      case "documents":
        return (
          <DocumentsSection
            data={documentsData}
            handleDrawerClose={handleDrawerClose}
            triggerIndex={triggerIndex}

          />
        );
      case "otherInfo":
        return (
          <OtherInfoSection
            data={otherInfoData}
            handleDrawerClose={handleDrawerClose}
     
          />
        );
      default:
        return null;
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleDrawerClose}
      BackdropProps={{
        style: {
          backdropFilter: isDrawerOpen ? "blur(5px)" : "none",
        },
      }}
      PaperProps={{
        sx: {
          width: "50%",
        },
      }}
    >
      {renderSectionContent()}
    </Drawer>
  );
};
