import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import { useSearchParams } from "react-router-dom";
import Loader from "../../Loader";
import "../../pages/css/showFile.css";
import { pdfjs, Document, Page } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function ShowFile({ exceltheme }) {
  const [params] = useSearchParams();
  const url = params.get("path");
  const [excelData, setExcelData] = useState({ rows: [], cols: [] });
  const [showLoader, setShowLoader] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [showPdf, setShowPdf] = useState(false);
  const containerRef = useRef(null);
  const path = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCESS_PRIVATE_FILE}/${url}`

  useEffect(() => {
    getFileAPI();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "s") {
        e.preventDefault();
      } else if (e.ctrlKey && e.key === "p") {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);



  const getFileAPI = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(path, {
        responseType: "blob",
      });
      const data = response.data;
      setFileData(data);
      if (
        data.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        ExcelRenderer(data, (err, resp) => {
          if (err) {
            console.error(err);
          } else {
            setExcelData({
              rows: resp.rows,
              cols: resp.cols,
            });
          }
        });
      } else if (data.type === "application/pdf") {
        // window.open(`${url}#toolbar=0`, "_self");
        setShowPdf(true);
        setShowLoader(false);
      } else {
      }
      setShowLoader(false);
    }catch(err){
      setShowLoader(false)
    }
  };

  const bodyStyle =
    exceltheme === "Dark" ? { backgroundColor: "#fff", color: "#000" } : {};

  function onDocumentLoadSuccess(numPages) {
    if (numPages?._pdfInfo?.numPages) {
      setNumPages(numPages._pdfInfo.numPages);
    }
  }
  return (
    <>
      <Loader show={showLoader} />
      {!showPdf ? <div
        className="table-container-show-file"
        ref={containerRef}
        onContextMenu={(e) => e.preventDefault()}
      >
        <OutTable
          data={excelData.rows}
          columns={excelData.cols}
          tableClassName="excel-table"
          tableHeaderRowClass="excel-table-header"
          showHeaderRow={false}
        />
        <style>{`body { background-color: ${bodyStyle.backgroundColor}; color: ${bodyStyle.color}; }`}</style>
      </div>
        : <div>
          {showPdf && (
            <div>
              <Document file={path} onLoadSuccess={onDocumentLoadSuccess}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <div className="page-container" 
                      key={`page_${index + 1}`}
                    // style={{ marginBottom: "16px" }}
                    >
                      <Page
                        pageNumber={index + 1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        customTextRenderer={false}
                        width={800}
                      />
                    </div>
                  ))}
                </div>
              </Document>
            </div>
          )}
        </div>}

    </>
  );
}

export default ShowFile;
