import { useState } from "react";
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

// sections
import { RegistrationForm } from '../sections/auth/registration';

import fullDark from '../assets/full-dark.png';
import fullLight from '../assets/full-light.png';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegistrationPage({toggleTheme}) {
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const toggleClick = () => {
    setTheme((theme === 'Dark') ? `Light` :`Dark`)
    toggleTheme();
  }
  return (
    <>
      <Helmet>
        <title> Register | Genesis Platform </title>
      </Helmet>
      <div className={`login-screen register-scroll `+ (theme==="Dark" ? "body-dark" : "body-light")}>
        <StyledRoot>       
        <Container maxWidth="sm" className="box-login">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
            <img src={(theme === "Dark" ? fullDark : fullLight)} className="logo-size" alt="logo"/><br/>
               </Typography>
                <h2>Welcome to Genesis</h2>
                <p>Fill in below details to register.</p> 
               <RegistrationForm toggleTheme={()=>toggleClick()} />
              </StyledContent>
             </Container>
         </StyledRoot>
        </div>
    </>
  );
}
