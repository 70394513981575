import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Grid,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import PostRequest from "../../../components/apiConnections/postRequest";
// import Loader from "./../../../Loader";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AccessRequestModal = ({
  open,
  handleClose,
  publicUrlId,
  fileId,
  fileType,
}) => {
  const [data, setData] = useState({
    publicUrlId: publicUrlId,
    firstName: "",
    lastName: "",
    email: "",
    fileType: fileType,
  });
  const [reclaimData, setReclaimData] = useState({
    email: "",
    publicUrlId: publicUrlId,
    requestedFileId: fileId,
  });
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [reclaimSuccess, setReclaimSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showRequestLoader, setShowRequestLoader] = useState(false);
  const [showReclaimLoader, setShowReclaimLoader] = useState(false);


  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(()=>{
    handleClose();
    setRequestSuccess(false);
    setReclaimSuccess(false);
  },[requestSuccess, reclaimSuccess])

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange2 = (e) => {
    setReclaimData({
      ...reclaimData,
      [e.target.name]: e.target.value,
    });
  };


  const RequestPrivateFile = async () => {
    setShowRequestLoader(true);
    try {
      PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_THIRD_PARTY_PRIVATE_FILE}`,
        { ...data, id: fileId }
      )
        .then((res) => {
          if (res?.data?.code === 200) {
            setData({
              publicUrlId: publicUrlId,
              firstName: "",
              lastName: "",
              email: "",
              id: fileId,
              fileType: fileType,
            });
            setShowRequestLoader(true);
            setTimeout(() => {
              setShowRequestLoader(false);
              setRequestSuccess(true);
              enqueueSnackbar("Access Request Sent Successfully", { variant: "success" });
            }, 2000);
          } else {
            enqueueSnackbar(res.data?.message, { variant: "warning" });
            setShowRequestLoader(false);
          }
        })
        .catch((err) => {
            setShowRequestLoader(false);
            enqueueSnackbar(err.message, { variant: "warning" });
        });
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setShowRequestLoader(false);
    }
  };

  const ReclaimAccess = async () => {
    setShowReclaimLoader(true);
    try {
      PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RECLAIM_THIRD_PARTY_PRIVATE_FILE}`,
        reclaimData
      )
        .then((res) => {
          if (res?.data?.code === 200) {
            setReclaimData({
              email: "",
              publicUrlId: publicUrlId,
              requestedFileId: fileId,
            });
            setShowReclaimLoader(true);
            setTimeout(() => {
              setShowReclaimLoader(false);
              setReclaimSuccess(true);
              enqueueSnackbar("Reclaim Request Sent Successfully", { variant: "success" });
            }, 2000);
          } else {
            enqueueSnackbar(res.data?.message, { variant: "warning" });
            setShowReclaimLoader(false);
          }
        })
        .catch((err) => {
          setReclaimData({
            email: "",
            publicUrlId: publicUrlId,
            requestedFileId: fileId,
          });
          setShowReclaimLoader(false);
          enqueueSnackbar(err.message, { variant: "warning" });
        });
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setShowReclaimLoader(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="access-request-modal"
        aria-describedby="modal to request access"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minHeight: "50%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Access Request
            </Typography>
            <ClearIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </div>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Request Access" {...a11yProps(0)} />
            <Tab label="Reclaim Access" {...a11yProps(1)} />
          </Tabs>

          <CustomTabPanel
            value={tabValue}
            index={0}
            className="custom-table-q-base"
          >
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="firstName"
                    placeholder="First Name"
                    sx={{ pb: 2 }}
                    value={data.firstName}
                    onChange={handleChange}
                    id="custom-input"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="lastName"
                    placeholder="Last Name"
                    sx={{ pb: 2 }}
                    value={data.lastName}
                    onChange={handleChange}
                    id="custom-input"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    name="email"
                    placeholder="Email"
                    sx={{ pb: 2 }}
                    value={data.email}
                    onChange={handleChange}
                    id="custom-input"
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{ mt: 2 }}
                  onClick={RequestPrivateFile}
                  variant="contained"
                  className="btn-new export-btn downlod-btn"
                  disabled={requestSuccess || showRequestLoader}
                >
                  {showRequestLoader ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : requestSuccess ? (
                    <CheckIcon />
                  ) : (
                    "Request Access"
                  )}
                </Button>
              </div>
            </form>
          </CustomTabPanel>
          <CustomTabPanel
            value={tabValue}
            index={1}
            className="custom-table-q-base"
          >
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    name="email"
                    placeholder="Enter Email to Reclaim Access"
                    sx={{ pb: 2 }}
                    value={reclaimData.email}
                    onChange={handleChange2}
                    id="reclaim-email-input"
                    required
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{ mt: 2 }}
                  onClick={ReclaimAccess}
                  variant="contained"
                  className="btn-new export-btn downlod-btn"
                  disabled={reclaimSuccess || showReclaimLoader}
                >
                  {showReclaimLoader ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : reclaimSuccess ? (
                    <CheckIcon />
                  ) : (
                    "Reclaim Access"
                  )}
                </Button>
              </div>
            </form>
          </CustomTabPanel>
        </Box>
      </Modal>
    </>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default AccessRequestModal;
