import { Tooltip, Typography, Grid } from "@mui/material";
import { useState } from "react";

export default function ConnectDomainInstructions() {
    const [ipCopied, setIpCopied] = useState(false);

    const handleIPCopy = (textToCopy) => {
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                setIpCopied(true);
                setTimeout(() => setIpCopied(false), 2000); // Reset copied state after 2 seconds
            })
            .catch((error) => console.log("Error copying text: ", error));
    };

    return (
        <Grid item xs={12} md={12} lg={12} className="domain-manual">
            <Typography className="fontSize">
                <span>Instructions : </span>
            </Typography>
            <ul>
                <li>Enter Subdomain in above input wherby you wanted to show security profile.</li>
                <li>Log in to your domain registrar's website.</li>
                <li>Find the DNS management section.</li>
                <li>
                    Add a new "A" Record as per the following details:
                    <ul>
                        <li>Record name: [Your Sub-domain which you have configured here]</li>
                        <li>Record Type: A</li>
                        <li>Value: Our IP &nbsp;
                            <span style={{ background: "rgb(204 204 204 / 17%)", padding: "4px 7px", borderRadius: "5px", border: "dashed 1px #cccccc08" }}>
                                {ipCopied ? (
                                    <Tooltip title="Copied" placement="top">
                                        <span onClick={() => handleIPCopy(process.env.REACT_APP_DNS)}>
                                            {process.env.REACT_APP_DNS}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Copy" placement="top">
                                        <span onClick={() => handleIPCopy(process.env.REACT_APP_DNS)}>
                                            {process.env.REACT_APP_DNS}
                                        </span>
                                    </Tooltip>
                                )}
                            </span>
                        </li>
                    </ul>
                </li>
                <li>Save your changes.</li>
                <li>Wait for DNS propagation (usually takes some time).</li>
                <li>Test the subdomain by accessing it in a browser.</li>
                <li>Ensure the external server is properly configured.</li>
                <li>Verify that the subdomain is working as expected.</li>
            </ul>
        </Grid>
    )
}