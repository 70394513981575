import { useState, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import { Link,Navigate } from 'react-router-dom';


import { getLocalStoarge } from '../../../components/common/allFunctions';
import './header.css';
import { useTheme } from '@emotion/react';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  // },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    to: './profile'
  },
  {
    label: 'Change Password',
    icon: 'eva:briefcase-outline',
    to: './change-password'
  }
  // {
  //   label: 'Request Url',
  //   icon: 'eva:link-2-outline',
  //   to: './dashboard/requestUrl'
  // },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   to: './setting'

  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const theme = useTheme();
  
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const logout = () => {
    window.localStorage.removeItem('roleId');
    window.localStorage.removeItem('R1');
    window.localStorage.removeItem('R2');
    window.localStorage.removeItem('usertime');
    window.localStorage.removeItem('userDetails');
    window.localStorage.removeItem('deleteAssessment');
    window.localStorage.removeItem('deleteRemediation');
    window.localStorage.removeItem('deleteFunction');



   };
  //  useEffect(()=>{
  //   setInterval(()=>{
  //     const themeName = localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : 'Dark';
  //     setTheme(themeName);
  //   }, 3000)
  //   }, [])
  return (
    <>
      <IconButton
      className='profile-bg-grey'
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
        title="Profile Setting"
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option, index) => (
            <Link to={option.to} key={index} style={{textDecoration : "none", color : (theme.palette.mode === 'dark') ? 'White': 'Black',padding : '2px', fontSize :'500'}}>
            <MenuItem key={index+'-'+option.label} >   
            {option.label}              
            </MenuItem>
            </Link>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Link to="/" style={{textDecoration : "none", color : (theme.palette.mode === 'dark') ? 'White': 'Black'}}>
        <MenuItem onClick={logout} sx={{ m: 1 }} >
           Logout
        </MenuItem>
        </Link>
      </Popover>
    </>
  );
}
