import axios from "axios";

async function GetRequest(session_url, option) {
    const role = parseInt(window.localStorage.getItem('roleId'));
    const saved = (role === 4) ? window.localStorage.getItem('R4') : window.localStorage.getItem('R1');
    const initialValue = JSON.parse(saved);
    const Token = (initialValue)?.accessToken;
    let config = {
        headers: { Authorization: `Bearer ${Token}` },
    };
    if (option?.headers) {
        config.headers = { ...config.headers, ...option?.headers }
        config.responseType = option?.responseType
    }
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(session_url, config)
                .then((getResponse) => {
                    resolve(getResponse);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response?.data?.message) reject(error.response?.data)
                        else reject(error.message)
                    }
                });
        } catch (error) {
            console.log("error ==>", error);
            if (error.response) {
                if (error.response?.data?.message) reject(error.response?.data)
                else reject(error.message)
            }
        }
    });
}
export default GetRequest;
