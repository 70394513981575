import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import {useState, useEffect} from 'react';
// @mui
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
import GaugeChart from 'react-gauge-chart'
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
  text: {
    visibility: 'hidden!important'
    },
}));

// ----------------------------------------------------------------------

AppRiskScore.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppRiskScore({ title, subheader, chartColors, chartData,riskScore, ...other }) {
  const theme = useTheme();
  const riskData = Number(riskScore) / 100;
  const [themename, setThemeName] = useState((window.localStorage.getItem('theme')==='Dark') ? 'Dark' : 'Light');

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
         <StyledChartWrapper dir="ltr">
         <GaugeChart id="gauge-chart3" 
          nrOfLevels={3} 
          colors={["#d32f2f", "#ed6c02", "#2e7d32"]}
          arcWidth={0.1} 
          arcPadding={0.05}
          cornerRadius={5} 
          needleColor = {(riskData>=0.66) ? "#2e7d32" : (riskData>=0.33 && riskData<0.66) ? "#ed6c02" : "#d32f2f"}
          needleBaseColor = {"rgb(144, 202, 249)"}
          textColor = {(riskData>=0.66) ? "#2e7d32" : (riskData>=0.33 && riskData<0.66) ? "#ed6c02" : "#d32f2f"}
          percent={riskData}           
        />     
        <span className='percentText' style={{color:(riskData>=0.66) ? "rgb(102, 187, 106)" : (riskData>=0.33 && riskData<0.66) ? "#ed6c02" : "#d32f2f"} }>{Math.round(riskData*100)}<small className='textSize'>/100</small></span>

      </StyledChartWrapper>
    </Card>
  );
}
