import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import Header from "./header";
import Nav from "./nav";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
// ----------------------------------------------------------------------

export default function DashboardLayout({ toggleTheme, theme, isShowSearch }) {
  const [open, setOpen] = useState(false);
  // const [windowSize, setWindowSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });

  // useEffect(() => {
  //   const originalWidth = 1366;
  //   const originalHeight = 274;
  //   const targetWidth = 1517;
  //   const targetHeight = 304;

  //   const calculateNewSize = () => {
  //     const scaleFactorWidth =
  //       (window.innerWidth * targetWidth) / originalWidth;
  //     const scaleFactorHeight =
  //       (window.innerHeight * targetHeight) / originalHeight;

  //     setWindowSize({
  //       width: scaleFactorWidth,
  //       height: scaleFactorHeight,
  //     });
  //   };

  //   const handleResize = () => {
  //     calculateNewSize();
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  // console.log(`Width: ${windowSize.width} && Height: ${windowSize.height}`);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} isShowSearch={isShowSearch} />

      <Nav
        openNav={open}
        toggleTheme={() => toggleTheme()}
        onCloseNav={() => setOpen(false)}
      />

      <Main
        className={
          theme == "Dark"
            ? "dark-mode-class"
            : "light-mode-class padding-topheader"
        }
      >
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
