import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from "react";

// @mui
import { Grid, Container, Stack,Card,TextField,Alert   } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

// components
import PostRequest from "../components/apiConnections/postRequest";


const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 300,
  minWidth:300,
  margin: '10px',
  // minHeight: '5vh',
  display: 'flex',
  // justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
// ----------------------------------------------------------------------

export default function RequestUrlPage() {
    const [inputValues, setInputValues] = useState({});
    const [alertData, setAlertData] = useState({});
    useEffect(() => {
      if (alertData.showalert) {
        setTimeout(() => {
          setAlertData({});
        }, 3000);
      }
    }, [alertData]);
    const do_save = async (e) => {
        e.preventDefault();
        setAlertData({
          alerttype: "info",
          showalert: true,
          message: "Please wait...",
        });
        try {
          const { requestUrl } = inputValues;
          if (
            requestUrl === null ||  requestUrl === "" ||
            requestUrl === undefined
          ) {
            setAlertData({
              alerttype: "error",
              showalert: true,
              message: "Please enter valid url.",
            });
           return false;


          }
          const payload = {
            requestUrl           
          };
          const refreshToken = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            {},
            {},
            "refreshToken"
           );
           if(refreshToken)
           {
          const userResult = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_URL_ENDPOINT}`,
            payload,
          );
          if (userResult && userResult.status === 200) {
            if (userResult.data.code === 200) {
                setAlertData({
                  alerttype: "success",
                  showalert: true,
                  message: "Request done successfully.",
                });          
             }
             else {
                setAlertData({
                  alerttype: "error",
                  showalert: true,
                  message: userResult.data.message,
                });
              }
          } else {
            setAlertData({
              alerttype: "error",
              showalert: true,
              message: userResult.data.message,
            });
          }
         }
        }
        catch (error) {
          setAlertData({
            alerttype: "error",
            showalert: true,
            message: "Something went wrong.",
          });
        }
    }  
    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
      };
  return (
    <>
      <Helmet>
        <title> Dashboard: Request Url | Genesis Platform</title>
      </Helmet>
      <Container maxWidth="l">
      <StyledContent>
      <Card sx={{p:5 , width:"400px"}}>
      <Grid item xs={12} md={6} lg={6}>
       {alertData.showalert ? (
        <Alert sx={{ mb: 2 }} severity={alertData.alerttype}>
          {alertData.message}
        </Alert>
      ) : null}
      <form onSubmit={(e) => do_save(e)}>
      <p>Enter Domain Name</p>
        <Stack spacing={3}>
          <TextField
            name="requestUrl"
            label="Enter Domain"
            onChange={handleChange}
          />        
        </Stack>  
        <br></br>   
        <LoadingButton  size="small" type="submit" variant="contained" color="success">
          Save
        </LoadingButton>             
      </form>
      </Grid>
     </Card>
     </StyledContent>
      </Container>
    </>
  );
}
