import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import {Container, Typography, } from '@mui/material';

// sections
import { ForgetPasswordForm } from '../sections/auth/forgettPassword';
import fullDark from "../assets/full-dark.png";
import fullLight from "../assets/full-light.png";
import { useState } from 'react';



const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));



const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgetPasswordPage({toggleTheme}) {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "Dark");

  const toggleClick = () => {
    setTheme(theme === "Dark" ? `Light` : `Dark`);
    toggleTheme();
  };
  return (
    <>
      <Helmet>
        <title> Forget | Genesis Platform  </title>
      </Helmet>
      <div className={
          `login-screen ` + (theme === "Dark" ? "body-dark" : "body-light")
        }>
      <StyledRoot>     
        <Container maxWidth="sm" className="box-login">
          <StyledContent>
              <Typography variant="h4" gutterBottom>
              <img
                  src={theme === "Dark" ? fullDark : fullLight}
                  className="logo-size"
                  alt="logo"
                />
                <br />
                 <h2 className='mb-2'>Enter Your Email Id</h2>
               </Typography>         
             <ForgetPasswordForm toggleTheme={() => toggleClick()} />
           </StyledContent>
         </Container>
       </StyledRoot>
      </div>
    </>
  );
}
