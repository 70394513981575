import { Card, CardHeader, Container, Grid, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import GetRequest from "../../components/apiConnections/getRequest.js";
import PostRequest from "../../components/apiConnections/postRequest.js";
import Loader from "../../Loader.js";
import { AppWidgetSummary } from "../../sections/@dashboard/app";
import "../css/ThirdPartyDashboard.css";
import SynchronizedCharts from "./SynchronizedCharts";

function ThirdPartyDashboardPage() {
  const username = localStorage.getItem("username") || "thirdpartytest";
  const { enqueueSnackbar } = useSnackbar();
  const [dashboardData, setDashboardData] = useState({
    totalRequestCount: 0,
    totalDownloadCount: 0,
    totalViewCount: 0,
    dataRequest: [],
    dataCount: [],
  });
  const [showLoader, setShowLoader] = useState(false);
  const [viewRange, setViewRange] = useState(365);
  const [areaChartData, setAreaChartData] = useState({
    categoriesData: ["34-34", "23-0"],
    seriesData: [4, 5],
  });
  const [isNoData, setIsNoData] = useState(false);

  useEffect(() => {
    const hasNoData = dashboardData.totalViewCount === 0;
    setIsNoData(hasNoData);
  }, [dashboardData]);

  useEffect(() => {
    getDashboardData();
  }, [viewRange]);

  const getDashboardData = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_THIRD_PARTY_DASHBOARD_DATA}?totalViewDayLimit=${viewRange}&username=${username}`,
          {}
        )
          .then((res) => {
            if (res?.data?.code === 200) {
              let resData = res.data.data;
              setDashboardData(resData);

              // Sort categoriesData array based on date
              let categories = resData?.dataCount.map((item) => item.date);
              // Custom sorting function for date strings in "Mon-YY" format
              categories.sort((a, b) => {
                const dateA = new Date(
                  `20${a.split("-")[1]}`,
                  monthToIndex(a.split("-")[0])
                );
                const dateB = new Date(
                  `20${b.split("-")[1]}`,
                  monthToIndex(b.split("-")[0])
                );
                return dateA - dateB;
              });

              let series = [];
              resData?.dataCount.forEach((item) => {
                series = [...series, item.viewCount];
              });

              setAreaChartData({
                ...areaChartData,
                categoriesData: categories,
                seriesData: series,
              });
            } else {
              enqueueSnackbar(res.data?.message, { variant: "error" });
            }
            setShowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar("Internal Error", { variant: "error" });
            setShowLoader(false);
            return false;
          });
      }
    } catch (error) {
      if (error.message) enqueueSnackbar(error.message, { variant: "error" });
      else enqueueSnackbar("Internal Error", { variant: "error" });
      setShowLoader(false);
      return false;
    }
  };

  // Assuming dashboardData.dataRequest is an array of objects
const sortedData = [...dashboardData.dataRequest];

sortedData.sort((a, b) => {
  // Compare 'approves' to move them to the top
  if (a.status === 'Requested' && b.status !== 'Requested') {
    return -1;
  } else if (a.status !== 'Requested' && b.status === 'Requested') {
    return 1;
  }

  // For other cases or if requests are the same, maintain the order
  return 0;
});

  // Function to convert month abbreviation to index (0-based)
  const monthToIndex = (month) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months.indexOf(month);
  };

  const handleViewChange = (e) => {
    setViewRange(e.target.value);
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <Typography variant="h4" sx={{ mt: 4, mb: 5 }}>
          <span className="heading-main ml25">Dashboard</span>
        </Typography>

        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                className="line-dark"
                title="Total Request"
                total={dashboardData?.totalRequestCount}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                className="line-dark"
                title="Total Views"
                total={dashboardData.totalViewCount}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                className="line-dark"
                title="Total Download"
                total={dashboardData.totalDownloadCount}
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <div className="flex-form-control">
                <CardHeader
                  className="width-full"
                  title={"Total Views"}
                  subheader={""}
                />

                {!isNoData && (
                  <FormControl sx={{ m: 1.5 }} style={{ width: "200px" }}>
                    <Select
                      value={viewRange}
                      onChange={handleViewChange}
                      displayEmpty
                      inputProps={{
                        style: { fontFamily: "Arial", color: "white" },
                      }}
                    >
                      <MenuItem value={365}>Last Year</MenuItem>
                      <MenuItem value={180}>Last 6 Months</MenuItem>
                      <MenuItem value={30}>Last Month</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </div>
              <SynchronizedCharts
                seriesData={areaChartData.seriesData}
                categoriesData={areaChartData.categoriesData}
              />
            </Card>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          className="mtop mbottom flex-property ipad-space"
        >
          <TableContainer component={Paper}>
            <CardHeader title={"Activity"} subheader={""} />
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="head-th">
                <TableCell>No.</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Requests</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>

              {dashboardData?.dataRequest?.length > 0 ? (
                <TableBody>
                  {sortedData.map((item, index) => (
                    <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                      <TableCell>{`${item.firstName} ${item.lastName}`}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.requests}</TableCell>
                      <TableCell>{item.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100px",
                        }}
                      >
                        <p>No Record Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </>
  );
}

export default ThirdPartyDashboardPage;
