import { useState, useEffect } from 'react';
// @mui
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { Input, Slide, Box, Button, IconButton, InputAdornment, ClickAwayListener } from '@mui/material';
import PostRequest from "../../../components/apiConnections/postRequest";
// utils
import { bgBlur } from '../../../utils/cssStyles';
// component
import Iconify from '../../../components/iconify';
import { SnackbarProvider, useSnackbar } from 'notistack';

import SearchIcon from '../../../assets/search-normal.svg';
import Loader from '../../../Loader';


// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar({ showSearch, isShowSearch, toggleTheme } ) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [alertData, setAlertData] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme'));

  useEffect(()=>{
  },[])
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const do_search = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    try {     
      const { search } = inputValues;
      if(new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(search)) {
         const payload = {
        "requestUrl":search
       }
       const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
       );
       if(refreshToken)
       {
        const searchResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_VENDOR_URL}`,
          payload
        );       
          // enqueueSnackbar(searchResult.data.message, { variant : 'success' });  
          setshowLoader(false);
          const searchData = searchResult.data;
          const searchURL = searchData.data.requestUrl !== undefined ? searchData.data.requestUrl : search;
          window.location.href = `/dashboard/vendorDetails/${searchURL}`;
          // navigate(`/dashboard/vendorDetails/${searchURL}`);
       }          
    }
    else
    {
      enqueueSnackbar('Please enter correct URL.', { variant : 'error' });    
      setshowLoader(false);
    }  
    }
    catch (error) {
      enqueueSnackbar('Request URL not find.', { variant : 'error' });    
      setshowLoader(false);
    }
  }
  // useEffect(()=>{
  //   const themeName = localStorage.getItem('theme');
  //   setTheme(themeName);
  // }, [])
  useEffect(()=>{
    setInterval(()=>{
      const themeName = localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : 'Dark';
      setTheme(themeName);
    }, 3000)
    }, [])

  return (    
    <>
    <Loader show={showLoader}/>
    <ClickAwayListener onClickAway={handleClose} >

      <div>
          {(!open && showSearch) ? (
          
             <IconButton onClick={handleOpen} className="right-search">
              {/* <Iconify icon="eva:search-fill" /> */}
              <div className={(theme=='Dark') ? 'dark-mode-searchbar' : 'light-mode-searchbar'}>

                {(window.location.pathname != '/dashboard/clientChat') ? ( <img src={SearchIcon} className="App-logo searchs-icon" alt="logo" /> ) : null}
                 
               </div>
              </IconButton>
        ) : null}

          <Box
      component="form"
      onSubmit={(e) => do_search(e)}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
    
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
         
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              name = 'search'
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" type="submit" className='searchbutton'>
              Search
            </Button>
          </StyledSearchbar>
        </Slide>
            </Box>
      </div>
    </ClickAwayListener>
    </>
  );
}
