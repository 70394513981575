import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import {
  Box,
  Button,
  CardHeader,
  Container,
  styled,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TableContainer from "@mui/material/TableContainer";
import Tabs from "@mui/material/Tabs";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import Delete from "../../assets/delete.svg";
import DeleteRequest from "../../components/apiConnections/deleteRequest";
import GetRequest from "../../components/apiConnections/getRequest";
import PostRequest from "../../components/apiConnections/postRequest";
import Loader from "../../Loader";
import "../css/upload-tab.css";
import ModalDialog from "../ModalDialog";
import CustomQuestionnaireModal from "./CustomQuestionnaireModal";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data Found</Box>
    </StyledGridOverlay>
  );
}

const UploadTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setShowLoader] = useState(false);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteId, setDeleteId] = useState(null);
  const [complianceRows, setComplianceRows] = useState([]);
  const [complianceColumns, setComplianceColumns] = useState([
    {
      field: "id",
      headerName: "No.",
      editable: false,
      width: 50,
    },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "complianceType",
      headerName: "Compliance Type",
      editable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      editable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "issueDate",
      headerName: "Issue Date",
      editable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortComparator: (v1, v2, param1, param2) => {
        let firstDate = moment(v1, "DD/MM/YYYY").valueOf();
        let secondDate = moment(v2, "DD/MM/YYYY").valueOf();
        return firstDate - secondDate;
      },
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      editable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortComparator: (v1, v2, param1, param2) => {
        let firstDate = moment(v1, "DD/MM/YYYY").valueOf();
        let secondDate = moment(v2, "DD/MM/YYYY").valueOf();
        return firstDate - secondDate;
      },
    },
    {
      field: "issuerName",
      headerName: "Issuer Name",
      editable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="icons-align mui-icons-btn upload-tab-btns">
            <Button
              onClick={() =>
                navigate(`/third-party/add-compliance`, {
                  state: { data: params.row, tabIndex: 0 },
                })
              }
            >
              <ModeEditOutlineIcon />
            </Button>
            <Button
              onClick={() => {
                setShowModal(true);
                setDeleteId(params.row._id);
              }}
            >
              <DeleteIcon />
            </Button>
          </Box>
        );
      },
    },
  ]);
  const [showModal, setShowModal] = useState(false);

  const [questionnaireRows, setQuestionnaireRows] = useState([]);
  const [questionnaireColumns, setQuestionnaireColumns] = useState([
    {
      field: "id",
      headerName: "No.",
      editable: false,
      width: 50,
    },
    {
      field: "category",
      headerName: "Name",
      editable: false,
      width: 250,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },

    {
      field: "type",
      headerName: "Type",
      editable: false,
      width: 250,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "publishedStatus",
      headerName: "Status",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className={`icons-align mui-icons-btn upload-tab-btns  
          ${params.row.completed == 0 ? "yellow-ut" : "green-ut"}
          `}>
            <Box sx={{ margin: "auto" }}>{params.row.publishedStatus}</Box>
          </Box>
        );
      },
    },
    // {
    //   field: "continue",
    //   headerName: "Continue",
    //   minWidth: 150,
    //   sortable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {
    //           params.row.completed == 0 ? <Box className='icons-align mui-icons-btn upload-tab-btns blue-ut'
    //             onClick={() =>
    //               navigate(`/third-party/questionnaire-set-answer`, {
    //                 state: { data: { ...params.row, _id: params.row.setId, type: params.row.setType, set: params.row.category }, tabIndex: 1 },
    //               })
    //             }
    //           >
    //             <Box sx={{ margin: "auto" }}
    //             >
    //               Click here
    //             </Box>
    //           </Box>
    //             : "N/A"
    //         }
    //       </>
    //     );
    //   },
    // },
    {
      field: "action",
      headerName: "Action",
      minWidth: 250,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="icons-align mui-icons-btn upload-tab-btns">
            <Button
              onClick={() =>
                params.row.completed === 0 ? 
                // enqueueSnackbar("Please complete and publish questionnaire set first", { variant: "warning" })
                navigate(`/third-party/questionnaire-set-answer`, {
                  state: { data: { ...params.row, _id: params.row.setId, type: params.row.setType, set: params.row.category }, tabIndex: 1 },
                })
                : navigate(`/third-party/add-questionnaire`, {
                  state: { data: params.row, tabIndex: 1 },
                })
              }
            >
              <ModeEditOutlineIcon />
            </Button>
            <Button
              onClick={() => {
                setShowModal(true);
                setDeleteId(params.row._id);
              }}
            >
              <DeleteIcon />
            </Button>
          </Box>
        );
      },
    },
  ]);

  const [documentRows, setDocumentRows] = useState([]);
  const [documentColumns, setDocumentColumns] = useState([
    { field: "id", headerName: "No.", editable: false, width: 50 },
    {
      field: "title",
      headerName: "Name",
      editable: false,
      width: 250,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      editable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "issueDate",
      headerName: "Issue Date",
      editable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    // {
    //   field: "title",
    //   headerName: "Title",
    //   editable: false,
    //   width: 150,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    // },
    ,
    {
      field: "action",
      headerName: "Action",
      minWidth: 150,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="icons-align mui-icons-btn upload-tab-btns">
            <Button
              onClick={() =>
                navigate(`/third-party/add-document`, {
                  state: { data: params.row, tabIndex: 2 },
                })
              }
            >
              <ModeEditOutlineIcon />
            </Button>
            <Button
              onClick={() => {
                setShowModal(true);
                setDeleteId(params.row._id);
              }}
            >
              <DeleteIcon />
            </Button>
          </Box>
        );
      },
    },
  ]);

  const [categoryRows, setCategoryRows] = useState([]);
  const [categoryColumns, setCategoryColumns] = useState([
    { field: "id", headerName: "No.", editable: false, width: 50 },
    {
      field: "category",
      headerName: "Category",
      editable: false,
      width: 550,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      width: 350,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="icons-align mui-icons-btn upload-tab-btns">
            <Button
              onClick={() =>
                navigate(`/third-party/add-info`, {
                  state: { data: params.row, tabIndex: 3 },
                })
              }
            >
              <ModeEditOutlineIcon />
            </Button>
            <Button
              onClick={() => {
                setShowModal(true);
                setDeleteId(params.row._id);
              }}
            >
              <DeleteIcon />
            </Button>
          </Box>
        );
      },
    },
  ]);

  const [showCustomQuestModal, setShowCustomQuestModal] = useState(false);

  useEffect(() => {
    getThirdPartyDetails();
  }, []);

  useEffect(() => {
    if (location.state) {
      setValue(location.state.tabIndex);
    }
  }, [location.state]);

  const getThirdPartyDetails = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_THIRD_PARTY_DETAILS}`,
          {}
        )
          .then((res) => {
            if (res?.data?.code === 200) {
              const complianceRes = res?.data?.data.complianceCard;
              const questionnaireRes = res?.data?.data.questionnaire;
              const documentRes = res?.data?.data.documents;
              const categoryRes = res?.data?.data.categories;
              setComplianceRows(
                complianceRes?.length
                  ? [
                    ...complianceRes?.map((d, i) => ({
                      ...d,
                      id: i + 1,
                      issueDate: moment(d.issueDate).format("DD-MM-YYYY"),
                      expiryDate: moment(d.expiryDate).format("DD-MM-YYYY"),
                    })),
                  ]
                  : []
              );
              setQuestionnaireRows(
                questionnaireRes?.length
                  ? [
                    ...questionnaireRes?.map((d, i) => ({
                      ...d,
                      id: i + 1,
                      publishedStatus: d.completed ? "Published" : "In-progress",
                    })),
                  ]
                  : []
              );
              setDocumentRows(
                documentRes?.length
                  ? [
                    ...documentRes?.map((d, i) => ({
                      ...d,
                      id: i + 1,
                      issueDate: moment(d.issueDate).format("DD-MM-YYYY"),
                    })),
                  ]
                  : []
              );
              setCategoryRows(
                categoryRes?.length
                  ? [
                    ...categoryRes?.map((d, i) => ({
                      ...d,
                      id: i + 1,
                    })),
                  ]
                  : []
              );

              setShowLoader(false);
            } else {
              enqueueSnackbar(res.data?.message, { variant: "warning" });
              setShowLoader(false);
            }
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setShowLoader(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h4 style={{ wordBreak: "break-word" }}>
          Are you sure you want to delete the record ?
        </h4>
      </Typography>
    );
  };

  const deleteAPiCall = (val) => {
    if (val === 0) {
      removeCompliance();
    } else if (val === 1) {
      removeQuestionnaire();
    } else if (val === 2) {
      removeDocument();
    } else if (val === 3) {
      removeCategory();
    }
    getThirdPartyDetails();
  };

  const removeCompliance = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        const endpoint =
          process.env.REACT_APP_DELETE_THIRD_PARTY_COMPLIANCE_CARD;
        DeleteRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          { complianceId: deleteId }
        )
          .then((res) => {
            enqueueSnackbar(res.data.message, { variant: "success" });
            setShowModal(false);
            setShowLoader(false);
            getThirdPartyDetails();
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setShowLoader(false);
    }
  };

  const removeQuestionnaire = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        const endpoint = process.env.REACT_APP_DELETE_THIRD_PARTY_QUESTIONNAIRE;
        DeleteRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          { questionnaireId: deleteId }
        )
          .then((res) => {
            enqueueSnackbar(res.data.message, { variant: "success" });
            setShowModal(false);
            setShowLoader(false);
            getThirdPartyDetails();
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setShowLoader(false);
    }
  };

  const removeDocument = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        const endpoint = process.env.REACT_APP_DELETE_THIRD_PARTY_DOCUMENT;
        DeleteRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          { documentId: deleteId }
        )
          .then((res) => {
            enqueueSnackbar(res.data.message, { variant: "success" });
            setShowModal(false);
            setShowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setShowLoader(false);
    }
  };

  const removeCategory = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        const endpoint = process.env.REACT_APP_DELETE_THIRD_PARTY_CATEGORY;
        DeleteRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          { categoryId: deleteId }
        )
          .then((res) => {
            enqueueSnackbar(res.data.message, { variant: "success" });
            setShowModal(false);
            setShowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setShowLoader(false);
    }
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mt: 4, mb: 5 }}>
          <span className="heading-main">Upload</span>
        </Typography>

        <Box className="tabspart " sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Compliances" {...a11yProps(0)} />
              <Tab label="Questionnaires" {...a11yProps(1)} />
              <Tab label="Documents" {...a11yProps(2)} />
              <Tab label="Other Info" {...a11yProps(3)} />
            </Tabs>
          </Box>

          {/* Tab-Panel - Compliances */}
          <CustomTabPanel
            value={value}
            index={0}
            className="custom-table-q-base"
          >
            <TableContainer component={Paper}>
              <div className="questbase-flex">
                <CardHeader
                  title={"Compliances"}
                  subheader={""}
                  className="quest-100"
                />
                <div className="flex-two-two-button">
                  <Button
                    variant="contained"
                    className="addnew export-btn m-r-shift"
                    onClick={() =>
                      navigate("/third-party/add-compliance", {
                        state: { tabIndex: 0 },
                      })
                    }
                  >
                    <AddIcon sx={{ fontSize: 20 }} /> Add New
                  </Button>
                </div>
              </div>
              <DataGrid
                sx={{ border: "none" }}
                rows={complianceRows}
                columns={complianceColumns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                }}
                autosizeOptions={{
                  includeOutliers: true,
                  includeHeaders: false,
                }}
                pageSizeOptions={[5, 10, 25]}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                  },
                }}
                autoHeight
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
              />
            </TableContainer>
          </CustomTabPanel>

          {/* Tab-Panel - Questionnaires */}
          <CustomTabPanel
            value={value}
            index={1}
            className="custom-table-q-base"
          >
            <TableContainer component={Paper}>
              <div className="questbase-flex">
                <CardHeader
                  title={"Questionnaires"}
                  subheader={""}
                  className="quest-100"
                />
                <div className="flex-two-two-button">
                  <Button
                    variant="contained"
                    className="addnew export-btn m-r-shift"
                    // onClick={() =>
                    //   navigate("/third-party/add-custom-questionnaire", {
                    //     state: { tabIndex: 1 },
                    //   })
                    // }
                    onClick={() => setShowCustomQuestModal(true)}
                  >
                    <AddIcon sx={{ fontSize: 20 }} /> Add Custom
                  </Button>
                  <Button
                    variant="contained"
                    className="addnew export-btn m-r-shift"
                    onClick={() =>
                      navigate("/third-party/add-questionnaire", {
                        state: { tabIndex: 1 },
                      })
                    }
                  >
                    <AddIcon sx={{ fontSize: 20 }} /> Add New
                  </Button>
                </div>
              </div>
              <DataGrid
                sx={{ border: "none", height: "409px", width: "100%" }}
                rows={questionnaireRows}
                columns={questionnaireColumns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                }}
                autosizeOptions={{
                  includeOutliers: true,
                  includeHeaders: false,
                }}
                pageSizeOptions={[5, 10, 25]}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                  },
                }}
                autoHeight
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
              />
            </TableContainer>
          </CustomTabPanel>

          {/* Tab-Panel - Documents */}
          <CustomTabPanel
            value={value}
            index={2}
            className="custom-table-q-base"
          >
            <TableContainer component={Paper}>
              <div className="questbase-flex">
                <CardHeader
                  title={"Documents"}
                  subheader={""}
                  className="quest-100"
                />
                <div className="flex-two-two-button">
                  <Button
                    variant="contained"
                    className="addnew export-btn m-r-shift"
                    onClick={() =>
                      navigate("/third-party/add-document", {
                        state: { tabIndex: 2 },
                      })
                    }
                  >
                    <AddIcon sx={{ fontSize: 20 }} /> Add New
                  </Button>
                </div>
              </div>
              <DataGrid
                sx={{ border: "none" }}
                rows={documentRows}
                columns={documentColumns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                }}
                autosizeOptions={{
                  includeOutliers: true,
                  includeHeaders: false,
                }}
                pageSizeOptions={[5, 10, 25]}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                  },
                }}
                autoHeight
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
              />
            </TableContainer>
          </CustomTabPanel>

          {/* Tab-Panel - Other Info */}
          <CustomTabPanel
            value={value}
            index={3}
            className="custom-table-q-base"
          >
            <TableContainer component={Paper}>
              <div className="questbase-flex">
                <CardHeader
                  title={"Other Info"}
                  subheader={""}
                  className="quest-100"
                />
                <div className="flex-two-two-button">
                  <Button
                    variant="contained"
                    className="addnew export-btn m-r-shift"
                    onClick={() =>
                      navigate("/third-party/add-info", {
                        state: { tabIndex: 3 },
                      })
                    }
                  >
                    <AddIcon sx={{ fontSize: 20 }} /> Add New
                  </Button>
                </div>
              </div>
              <DataGrid
                sx={{ border: "none", height: "409px", width: "100%" }}
                rows={categoryRows}
                columns={categoryColumns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                }}
                autosizeOptions={{
                  includeOutliers: true,
                  includeHeaders: false,
                }}
                pageSizeOptions={[5, 10, 25]}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                  },
                }}
                autoHeight
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
              />
              {/* <CollapsibleTable data={categoryRows}/> */}
            </TableContainer>
          </CustomTabPanel>
        </Box>
      </Container>
      <ModalDialog
        handleClose={() => setShowModal(false)}
        open={showModal}
        onSubmit={() => deleteAPiCall(value)}
        contentData={deleteModalContent}
      />
      {showCustomQuestModal ? (
        <CustomQuestionnaireModal
          handleClose={() => setShowCustomQuestModal(false)}
          open={showCustomQuestModal}
          data={null}
          tabIndex={1}
        />
      ) : null}
    </>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default UploadTab;
