// component
import SvgColor from "../../../components/svg-color";
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard/app",
    icon: icon("dashboard"),
  },
  {
    title: "Ecosystem",
    path: "/dashboard/vendors",
    icon: icon("peoples"),
  },
  {
    title: "Attack Surface",
    path: "/dashboard/attacks",
    icon: icon("attack-icon"),
  },
  {
    title: "Questionnaire library",
    path: "/dashboard/questionnaire-library",
    icon: icon("questionnaire-line"),
  },
  {
    title: "Assessment",
    path: "/dashboard/assessment",
    icon: icon("assessment-icon"),
  },
  {
    title: "chat",
    path: "/dashboard/clientChat",
    icon: icon("chat-icon"),
  },
];
const navConfigAdmin = [
  {
    title: "Upload Json",
    path: "/admin/addfile",
    icon: icon("ic_analytics"),
  },
  {
    title: "All Ecosystems",
    path: "/admin/vendors",
    icon: icon("peoples"),
  },
  {
    title: "All Attack",
    path: "/admin/attacks",
    icon: icon("attack-icon"),
  },
  {
    title: "All Requests",
    path: "/admin/requests",
    icon: icon("ic_file"),
  },
  {
    title: "All Attack Requests",
    path: "/admin/all-attack-requests",
    icon: icon("attack-icon"),
  },
  {
    title: "Questionnaire Import",
    path: "/admin/questionnaire-import",
    icon: icon("questionnaire-line"),
  },
];

const navConfigVendor = [
  {
    title: "Assessments",
    path: "/vendor/vendorAssessment",
    icon: icon("ic_file"),
  },
  {
    title: "Remediation",
    path: "/vendor/vendorRemediation",
    icon: icon("Group1810"),
  },
  {
    title: "Compliance",
    path: "compliance",
    icon: icon("ic_analytics"),
  },
  {
    title: "Chat",
    path: "vendorChat",
    icon: icon("chat-icon"),
  },
];

const navConfigThirdParty = [
  {
    title: "Dashboard",
    path: "/third-party/dashboard",
    icon: icon("dashboard"),
  },
  {
    title: "Upload",
    path: "/third-party/upload-tab",
    icon: icon("upload"),
  },
  {
    title: "Security Center",
    path: "/third-party/security-center",
    icon: icon("Group1810"),
  },
  {
    title: "Public URL",
    path: "/third-party/public-url",
    icon: icon("pages-line"),
  },
  {
    title: "Requests",
    path: "/third-party/requests",
    icon: icon("requests"),
  },
  {
    title: "Questionnaire Library",
    path: "/third-party/questionnaire",
    icon: icon("questionnaire-line"),
  }
];

export { navConfig, navConfigAdmin, navConfigVendor, navConfigThirdParty };
